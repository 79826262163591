import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SettingsIcon from '../../../icons/settingsIcon';
import LogoutIcon from '../../../icons/logoutIcon';
import { GlobalState } from '../../../state/@types/redux.interface';
import DrawerMenuOption from './drawerMenuOption';

const LowerDrawerOptionsWrapper = styled.div`
    bottom: 70px;
    position: absolute;
`;

interface DrawerLowerSectionInterface {
    setAreSettingsOpened: React.Dispatch<React.SetStateAction<boolean>>;
    areSettingsOpened: boolean;
}

const DrawerLowerSection: FC<DrawerLowerSectionInterface> = ({ setAreSettingsOpened, areSettingsOpened }) => {
	const { t } = useTranslation();
	const { user } = useSelector((state: GlobalState) => state.user);

	return (
		<LowerDrawerOptionsWrapper>
			<DrawerMenuOption
				boldLabel={false}
				clickAction={() => setAreSettingsOpened(!areSettingsOpened)}
				icon={(
					<SettingsIcon />
				)}
				label={t('common.settings')}
				labelColor="#646464"
			/>
			<DrawerMenuOption
				boldLabel={false}
				closeDrawerOnClick
				icon={(
					<LogoutIcon />
				)}
				label={user.email ? t('common.logout') : t('common.login')}
				labelColor="#646464"
				link="/login"
			/>
		</LowerDrawerOptionsWrapper>
	);
};
export default DrawerLowerSection;
