import { AxiosResponse } from 'axios';
import { MenuInterface } from 'restpay-api-lib/lib/@types/_interfaces/menus/menu.interface';
import { fetchRestaurantMenuDetails } from '../services/restaurantMenuService';
import { MenuStateTypesInterface } from './@types/menu/menuStateTypes.interface';
import { Action } from './@types/action.interface';
import { ItemInOrderInterface } from './@types/cart/itemInOrder.interface';

const initialState: MenuStateTypesInterface = {
	fetched: false,
	loading: false,
	error: false,
	menu: null,
	currentSelectedItem: null,
};

const FETCH_RESTAURANT_MENU_DETAILS = 'FETCH_RESTAURANT_MENU_DETAILS';
const SET_CURRENT_SELECTED_ITEM = 'SET_CURRENT_SELECTED_ITEM';

export const fetchRestaurantMenuDetailsAction = (restaurantId: string) => ({
	type: FETCH_RESTAURANT_MENU_DETAILS,
	payload: fetchRestaurantMenuDetails(restaurantId),
});

export const setCurrentSelectedItemAction = (itemInOrder: ItemInOrderInterface) => ({
	type: SET_CURRENT_SELECTED_ITEM,
	payload: itemInOrder,
});

export default (
	state: MenuStateTypesInterface = initialState,
	action,
): MenuStateTypesInterface => {
	switch (action.type) {
		case `${FETCH_RESTAURANT_MENU_DETAILS}_PENDING`:
			return {
				...state,
				loading: true,
				error: false,
				fetched: false,
				menu: null,
			};
		case `${FETCH_RESTAURANT_MENU_DETAILS}_REJECTED`:
			return {
				...state,
				loading: false,
				error: true,
				fetched: false,
				menu: null,
			};
		case `${FETCH_RESTAURANT_MENU_DETAILS}_FULFILLED`:
			return {
				...state,
				loading: false,
				error: false,
				fetched: true,
				menu: action.payload.data,
			};
		case SET_CURRENT_SELECTED_ITEM:
			return {
				...state,
				currentSelectedItem: action.payload,
			};
		default:
			return state;
	}
};
