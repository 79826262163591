import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import SygnetSVG from '../../icons/sygnet.svg';
import AppButton from '../common/button';

const ButtonWrapper = styled.div`
    align-items: flex-start;
    background-color: ${({ theme }) => theme.colors.brandPurple};
    color: #fff;
    display: none;
    margin: 0 -26px;
    margin-top: -1px;
    padding: 25px 5px 20px 15px;
    position: relative;
    @media (min-width: 350px) {
        padding: 25px 5px 20px 30px;
    }
`;
const SygnetWrapper = styled.span`
    background-color: #fff;
    border-radius: 100%;
    display: inline-block;
    padding: 15.5px 12.5px;
`;
const StyledRight = styled.div`
    padding-left: 20px;
`;
const StyledText = styled.p`
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
`;
const StyledButtonsWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 15px;
`;
const StyledButton = styled(AppButton)`
    background-color: #fff;
    font-size: 0.9rem;
    line-height: 35px;
    margin-right: 20px;
    min-width: auto;
    padding: 0 15px;

    &.gray {
        background-color: ${({ theme }) => theme.colors.brandPurple};
        color: #955ca2;
    }

    @media (min-width: 375px) {
        font-size: 1rem;
    }
`;

const AddToHomeScreenAndroid = () => {
	const { t } = useTranslation();
	return (
		<ButtonWrapper>
			<div>
				<SygnetWrapper>
					<SygnetSVG />
				</SygnetWrapper>
			</div>
			<StyledRight>
				<StyledText>{t('a2hs.payFaster')}</StyledText>
				 <StyledButtonsWrapper>
					<StyledButton
						onClick={() => (window?.androidA2HS ? window.androidA2HS.prompt() : null)}
					>
						{t('a2hs.install')}
					</StyledButton>
				 </StyledButtonsWrapper>
			</StyledRight>
		</ButtonWrapper>
	);
};

export default AddToHomeScreenAndroid;
