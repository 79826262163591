import React from 'react';
import styled from 'styled-components';
import RotateScreenSVG from '../../icons/rotate-screen.svg';
import SEO from '../seo';

const StyledLockWrapper = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
  svg {
    animation: shake 1.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    animation-delay: 1s;
    height: 70vh;
  }
  svg,
  svg * {
    fill: ${({ theme }) => theme.colors.brandPurple} !important;
  }
  @keyframes shake {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(90deg);
    }
  }
`;
const StyledTitle = styled.p`
  color: ${({ theme }) => theme.colors.brandPurple};
  font-size: 1.3rem;
  font-weight: 900;
  margin: 0 0 30px;
`;

const PortraitOrientationLock = () => (
	<>
		<SEO title="Prosimy o zmianę ułożenia telefonu" />
		<StyledLockWrapper>
			<StyledTitle>Prosimy o zmianę ułożenia telefonu</StyledTitle>
			<RotateScreenSVG />
		</StyledLockWrapper>
	</>
);

export default PortraitOrientationLock;
