import { navigate } from 'gatsby';
import { RestaurantTableInterface } from 'restpay-api-lib/lib/@types/_interfaces/tables/restaurantTable.interface';
import { AxiosResponse } from 'axios';
import { fetchTableDetailsByTableDto } from '../services/tableService';
import { LINKS } from '../constants/links';
import { TableStateTypesInterface } from './@types/table/tableStateTypes.interface';
import { Action } from './@types/action.interface';

const initialState: TableStateTypesInterface = {
	fetched: false,
	loading: false,
	error: false,
	table: null,
};

const FETCH_TABLE_DETAILS_BY_ID = 'FETCH_TABLE_DETAILS_BY_ID';
const FETCH_TABLE_DETAILS_BY_CODE = 'FETCH_TABLE_DETAILS_BY_CODE';
const CLEAR_TABLE_DETAILS = 'CLEAR_TABLE_DETAILS';

export const fetchTableDetailsByCodeAction = (tableCode: string) => ({
	type: FETCH_TABLE_DETAILS_BY_CODE,
	payload: fetchTableDetailsByTableDto({
		filters: { code: tableCode },
		pagination: { skip: true },
	}),
});

export const fetchTableDetailsByIdAction = (tableId: string) => ({
	type: FETCH_TABLE_DETAILS_BY_ID,
	payload: fetchTableDetailsByTableDto({
		filters: { id: tableId },
		pagination: { skip: true },
	}),
});

export const clearTableDetailsAction = () => ({
	type: CLEAR_TABLE_DETAILS,
});

export default (
	state: TableStateTypesInterface = initialState,
	action: Action<string, AxiosResponse<RestaurantTableInterface>>,
): TableStateTypesInterface => {
	switch (action.type) {
		case `${FETCH_TABLE_DETAILS_BY_ID}_PENDING`:
		case `${FETCH_TABLE_DETAILS_BY_CODE}_PENDING`:
			return {
				...state,
				loading: true,
				error: false,
				fetched: false,
				table: null,
			};
		case `${FETCH_TABLE_DETAILS_BY_ID}_REJECTED`:
		case `${FETCH_TABLE_DETAILS_BY_CODE}_REJECTED`:
			return {
				...initialState,
				error: true,
			};
		case `${FETCH_TABLE_DETAILS_BY_CODE}_FULFILLED`:
			navigate(LINKS.TABLE + action.payload.data.id);
			return {
				...state,
				loading: false,
				error: false,
				fetched: true,
				table: action.payload.data,
			};
		case `${FETCH_TABLE_DETAILS_BY_ID}_FULFILLED`:
			return {
				...state,
				loading: false,
				error: false,
				fetched: true,
				table: action.payload.data,
			};
		case CLEAR_TABLE_DETAILS:
			return {
				...initialState,
			};
		default:
			return state;
	}
};
