import styled from 'styled-components';

const AppText = styled.p`
  color: ${({ theme }) => theme.colors.fontBlack};
  font-size: 1rem;
  line-height: 1.6rem;
  a,
  b,
  strong {
    color: ${({ theme }) => theme.colors.brandPurple};
    font-weight: bold;
    text-decoration: none;
  }
`;

export default AppText;
