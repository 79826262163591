import * as React from 'react';
import { FC } from 'react';

const ArrowIcon: FC<React.SVGProps<SVGSVGElement>> = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		height={10.667}
		viewBox="0 0 10.667 10.667"
		width={10.667}
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M5.333 0l-.94.94 3.72 3.727H0V6h8.113l-3.72 3.727.94.94 5.333-5.333z"
			data-name="Path 3788"
		/>
	</svg>
);

export default ArrowIcon;
