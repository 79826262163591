import { getPaymentStatus } from "../services/paymentService";
import { EnumPaymentStatus } from "../constants/paymentStatuses";

const initialState = {
	loading: false,
	error: false,
	orderId: null,
	status: EnumPaymentStatus.PENDING,
	withInvoice: false,
};

const FETCH_PAYMENT_STATUS = "FETCH_PAYMENT_STATUS";

export const fetchPaymentStatusAction = (paymentId, orderId) => ({
	type: FETCH_PAYMENT_STATUS,
	payload: getPaymentStatus(paymentId, orderId),
});

export default (state = initialState, action) => {
	switch (action.type) {
		case `${FETCH_PAYMENT_STATUS}_PENDING`:
			return {
				...initialState,
				loading: true,
				error: false,
			};
		case `${FETCH_PAYMENT_STATUS}_REJECTED`:
			return {
				...initialState,
				loading: false,
				error: true,
			};
		case `${FETCH_PAYMENT_STATUS}_FULFILLED`:
			return {
				...state,
				loading: false,
				error: false,
				orderId: action.payload.data.orderId,
				status: action.payload.data.status,
				withInvoice: action.payload.data.withInvoice,
			};
		default:
			return state;
	}
};
