import React from "react";
import { Provider } from "react-redux";
import promise from "redux-promise-middleware";
import { applyMiddleware, createStore as reduxCreateStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage";
import rootReducer from ".";

const persistConfig = {
	key: "root",
	storage,
	whitelist: ["cart"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = composeWithDevTools({});
const middlewares = [promise];
const createStore = () => reduxCreateStore(persistedReducer, composeEnhancers(applyMiddleware(...middlewares)));

const store = createStore();
const persistor = persistStore(store);

const ReduxProvider = ({ element }) => (
	<Provider store={store}>
		<PersistGate persistor={persistor}>{element}</PersistGate>
	</Provider>
);
export default ReduxProvider;
