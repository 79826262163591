import { navigate } from "gatsby";
import { generatePaymentUrl, redirectToPaymentProvider } from "../services/paymentService";
import { LINKS } from "../constants/links";

const initialState = {
	loading: false,
	error: false,
	redirect: false,
	url: "",
	processingOrder: null,
	methods: [],
};

const CLEAR_ORDER_DATA = "CLEAR_ORDER_DATA";
const FETCH_PAYMENT_URL = "FETCH_PAYMENT_URL";

export const fetchPaymentUrlAction = (orderId, bruttoPrice, tip, paymentType, nip) => ({
	type: FETCH_PAYMENT_URL,
	payload: generatePaymentUrl(orderId, bruttoPrice, tip, paymentType, nip),
});

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_ORDER_DATA:
			return initialState;
		case `${FETCH_PAYMENT_URL}_PENDING`:
			return {
				...state,
				loading: true,
				redirect: false,
			};
		case `${FETCH_PAYMENT_URL}_REJECTED`:
			navigate(LINKS.PAYMENT_FAILED);
			return {
				...state,
				loading: false,
				error: true,
				redirect: true,
			};
		case `${FETCH_PAYMENT_URL}_FULFILLED`:
			if (action?.payload?.data?.redirectUrl) {
				redirectToPaymentProvider(action.payload.data.redirectUrl);
			}
			return {
				...state,
				loading: false,
				error: false,
				redirect: true,
			};
		default:
			return state;
	}
};
