import { sendContactEmail } from "../services/contactService";
import { getUserEmailAddress } from "../utils/localStorage";

const initialState = {
	name: "",
	email: getUserEmailAddress(),
	body: "",
	title: "",
	loading: false,
	error: false,
	redirect: false,
};

const CHANGE_CONTACT_FORM_VALUE = "CHANGE_CONTACT_FORM_VALUE";
const SEND_CONTACT_EMAIL = "SEND_CONTACT_EMAIL";
const CLEAR_CONTACT_FORM = "CLEAR_CONTACT_FORM";

export const sendContactEmailAction = (name, email, title, body) => ({
	type: SEND_CONTACT_EMAIL,
	payload: sendContactEmail(name, email, title, body),
});
export const changeContactFormValueAction = (name, value) => ({
	type: CHANGE_CONTACT_FORM_VALUE,
	payload: {
		name,
		value,
	},
});
export const clearContactFormAction = () => ({
	type: CLEAR_CONTACT_FORM,
});

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_CONTACT_FORM:
			return {
				...initialState,
			};
		case `${SEND_CONTACT_EMAIL}_PENDING`:
			return {
				...state,
				loading: true,
				redirect: false,
			};
		case `${SEND_CONTACT_EMAIL}_REJECTED`:
			return {
				...state,
				loading: true,
				error: true,
				redirect: true,
			};
		case `${SEND_CONTACT_EMAIL}_FULFILLED`:
			return {
				...state,
				loading: false,
				error: false,
				redirect: true,
			};
		case CHANGE_CONTACT_FORM_VALUE:
			return {
				...state,
				[action.payload.name]: action.payload.value,
			};
		default:
			return state;
	}
};
