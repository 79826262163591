/* eslint-disable */
import * as React from "react";

const StoreIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg height={18} viewBox="0 0 18 18" width={18} xmlns="http://www.w3.org/2000/svg" {...props}>
        <g data-name="Group 5855">
            <path d="M0 0h18v18H0z" data-name="Rectangle 3284" fill="none" />
        </g>
        <g data-name="Group 5857">
            <g data-name="Group 5856">
                <path
                    d="M15.93 6.927l-.735-2.913A1.363 1.363 0 0013.858 3H4.136a1.362 1.362 0 00-1.33 1.013l-.734 2.914a2.168 2.168 0 00.434 1.92 2.617 2.617 0 00.2.193v4.627A1.372 1.372 0 004.1 15h9.8a1.372 1.372 0 001.4-1.333V9.04a2.531 2.531 0 00.2-.187 2.16 2.16 0 00.43-1.926zm-2.093-2.6l.735 2.913a.871.871 0 01-.175.78.833.833 0 01-.658.313.858.858 0 01-.847-.76l-.406-3.24zM9.7 4.333h1.372l.378 3.013a.868.868 0 01-.231.713.854.854 0 01-.665.273.867.867 0 01-.854-.872zM6.544 7.347l.385-3.013H8.3V7.46a.878.878 0 01-.9.873.861.861 0 01-.623-.273.917.917 0 01-.233-.713zM3.429 7.24l.707-2.907h1.379l-.406 3.24a.864.864 0 01-.847.76.817.817 0 01-.651-.313.85.85 0 01-.182-.78zm.671 6.427v-4.02a1.327 1.327 0 00.161.02 2.244 2.244 0 001.569-.634 2.309 2.309 0 001.617.633 2.275 2.275 0 001.561-.62 2.355 2.355 0 001.6.62 2.278 2.278 0 001.568-.633 2.244 2.244 0 001.568.633 1.327 1.327 0 00.161-.02v4.02z"
                    data-name="Path 3802"
                    fill="#1e1e1c"
                />
            </g>
        </g>
    </svg>
);

export default StoreIcon;
