import { callAWaiter, getOrderDetailsById } from '../services/orderService';

const initialState = {
	fetched: false,
	loading: false,
	actualizedNotificationVisible: false,
	waiterCalledNotificationVisible: false,
	loadedOnce: false,
	customLogo: null,
	error: false,
	divide: false,
	data: {
		price: 0,
		tip: 0,
		waiterCalled: false,
		details: [],
	},
	issueAnInvoice: false,
	invoiceNip: '',
	invoiceNipValid: true,
};
const SET_ORDER_DATA = 'SET_ORDER_DATA';
const FETCH_ORDER_DATA = 'FETCH_ORDER_DATA';
const CLEAR_ORDER_DATA = 'CLEAR_ORDER_DATA';
const SET_TIP = 'SET_TIP';
const CLEAR_NOTIFICATION_VISIBLE = 'CLEAR_NOTIFICATION_VISIBLE';
const SET_EMAIL = 'SET_EMAIL';
const SET_ISSUE_AN_INVOICE = 'SET_ISSUE_AN_INVOICE';
const SET_INVOICE_NIP = 'SET_INVOICE_NIP';
const CALL_A_WAITER = 'CALL_A_WAITER';
const DIVIDE_BILL = 'DIVIDE_BILL';
const CLEAR_DIVIDE_BILL = 'CLEAR_DIVIDE_BILL';

export const divideBillAction = () => ({
	type: DIVIDE_BILL,
});

export const clearOrderDataAction = () => ({
	type: CLEAR_ORDER_DATA,
});

export const clearDivideBillAction = () => ({
	type: CLEAR_DIVIDE_BILL,
});

export const setOrderData = (order) => ({
	type: SET_ORDER_DATA,
	payload: { data: order },
});

export const fetchOrderDataByIdAction = (orderId) => ({
	type: FETCH_ORDER_DATA,
	payload: getOrderDetailsById(orderId),
});

export const setTipAction = (tip) => ({
	type: SET_TIP,
	payload: parseFloat(tip),
});

export const clearNotificationVisibleAction = () => ({
	type: CLEAR_NOTIFICATION_VISIBLE,
	payload: true,
});

export const setIssueAnInvoiceAction = (boolean) => ({
	type: SET_ISSUE_AN_INVOICE,
	payload: boolean,
});

export const setInvoiceNipAction = (invoiceNip) => ({
	type: SET_INVOICE_NIP,
	payload: invoiceNip,
});

export const callAWaiterAction = (orderId) => ({
	type: CALL_A_WAITER,
	payload: callAWaiter(orderId),
});

const isBillActualized = (prevState, newState) => {
	if (!prevState || !newState || prevState.actualizedNotificationVisible) {
		return false;
	}
	if (newState.lastPaymentStatus) {
		return false;
	}
	return prevState.value !== newState.value;
};

const handleOrderFulfilled = (state, action) => {
	const { data } = action.payload;
	const actualizedNotificationVisible = isBillActualized(state.data, data);
	const waiterCalledNotificationVisible = !state.data.waiterCalled && data.waiterCalled;
	const billEntered = data.positions && data.positions.length > 0;

	return {
		...state,
		loading: false,
		loadedOnce: true,
		loaderVisible: false,
		actualizedNotificationVisible,
		waiterCalledNotificationVisible,
		error: false,
		fetched: true,
		customLogo: data.restaurant?.images?.find((image) => image.type === 'LOGO')?.url || null,
		billEntered,
		data: {
			...data,
			tip: !state.data.tip ? data.tip : state.data.tip,
		},
	};
};

export default (state = initialState, action) => {
	switch (action.type) {
		case DIVIDE_BILL:
			return {
				...state,
				divide: true,
			};
		case CLEAR_DIVIDE_BILL:
			return {
				...state,
				divide: false,
			};
		case CLEAR_ORDER_DATA:
			return initialState;
		case `${SET_ORDER_DATA}`:
			return handleOrderFulfilled(state, action);
		case `${FETCH_ORDER_DATA}_PENDING`:
			return {
				...state,
				loading: true,
				loaderVisible: !state.loadedOnce,
				fetched: false,
			};
		case `${FETCH_ORDER_DATA}_REJECTED`:
			return {
				...initialState,
				loaderVisible: false,
				loading: false,
				error: true,
			};
		case `${FETCH_ORDER_DATA}_FULFILLED`:
			return handleOrderFulfilled(state, action);
		case CLEAR_NOTIFICATION_VISIBLE:
			return {
				...state,
				actualizedNotificationVisible: false,
				waiterCalledNotificationVisible: false,
			};
		case SET_TIP:
			return {
				...state,
				data: {
					...state.data,
					tip: action.payload,
				},
			};
		case SET_EMAIL:
			return {
				...state,
				email: action.payload,
			};
		case SET_ISSUE_AN_INVOICE:
			return {
				...state,
				issueAnInvoice: action.payload,
				invoiceNipValid: !action.payload || (action.payload && /^[0-9]{10}$/.test(state.invoiceNip)),
			};
		case SET_INVOICE_NIP:
			return {
				...state,
				invoiceNip: action.payload,
				invoiceNipValid: !state.issueAnInvoice || (state.issueAnInvoice && /^[0-9]{10}$/.test(action.payload)),
			};
		default:
			return state;
	}
};
