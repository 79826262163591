/* eslint-disable */
import * as React from "react";

const LogoutIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg height={18} viewBox="0 0 18 18" width={18} xmlns="http://www.w3.org/2000/svg" {...props}>
        <g data-name="Group 5866">
            <path d="M0 0h18v18H0z" data-name="Path 3814" fill="none" />
        </g>
        <g data-name="Group 5867">
            <path
                d="M12.333 6.333l-.94.94 1.053 1.06H7v1.334h5.447l-1.054 1.053.94.947L15 9zm-8-2H9V3H4.333A1.337 1.337 0 003 4.333v9.333A1.337 1.337 0 004.333 15H9v-1.333H4.333z"
                data-name="Path 3815"
                fill="#646464"
            />
        </g>
    </svg>
);

export default LogoutIcon;
