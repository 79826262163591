import * as React from 'react';
import { SVGProps } from 'react';

const ListIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		height={18}
		viewBox="0 0 18 18"
		width={18}
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M0 0h18v18H0Z"
			data-name="Path 3829"
			fill="none"
		/>
		<path
			d="M8.333 5.667h4V7h-4Zm0 2.667h4v1.333h-4Zm0 2.667h4v1.333h-4ZM5.667 5.667H7V7H5.667Zm0 2.667H7v1.333H5.667Zm0 2.667H7v1.333H5.667ZM14.4 3H3.6a.6.6 0 0 0-.6.6v10.8a.644.644 0 0 0 .6.6h10.8a.707.707 0 0 0 .6-.6V3.6a.644.644 0 0 0-.6-.6Zm-.733 10.667H4.333V4.333h9.333Z"
			data-name="Path 3830"
			fill="#646464"
		/>
	</svg>
);

export default ListIcon;
