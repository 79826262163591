import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { setDrawerAction } from '../../../state/layout.reducer';

const DrawerMenuOptionWrapper = styled.button`
    align-items: center;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    margin-bottom: 20px;
    padding: 0;
    text-decoration: none;
`;
const DrawerMenuOptionLabel = styled.div<{labelColor: string, boldLabel: boolean}>`
    color: ${({ labelColor }) => labelColor};
    font-size: 14px;
    font-weight: ${({ boldLabel }) => (boldLabel ? 'bold' : 'regular')};
    margin-left: 15px;
`;

interface DrawerMenuOptionInterface {
    label: string;
    icon: React.SVGProps<SVGSVGElement>;
    link?: string;
    boldLabel?: boolean;
    labelColor?: string;
    closeDrawerOnClick?: boolean;
    clickAction?: () => void;
}

const DrawerMenuOption: FC<DrawerMenuOptionInterface> = ({
	icon, label, link, boldLabel = true, labelColor = '#000', closeDrawerOnClick = false, clickAction,
}) => {
	const dispatch = useDispatch();
	const closeDrawer = () => {
		if (closeDrawerOnClick) dispatch(setDrawerAction(false));
	};

	return (
		<DrawerMenuOptionWrapper
			as={clickAction ? null : Link}
			onClick={clickAction ?? closeDrawer}
			to={link ?? null}
		>
			{icon}
			<DrawerMenuOptionLabel
				boldLabel={boldLabel}
				labelColor={labelColor}
			>
				{label}
			</DrawerMenuOptionLabel>
		</DrawerMenuOptionWrapper>
	);
};
export default DrawerMenuOption;
