import axios from "axios";
import { navigate } from "gatsby";
import { v4 as uuid } from "uuid";
import LogRocket from "logrocket";
import { API_LINKS, LINKS } from "../constants/links";
import { removeAuthDataFromLocalStorage, saveAuthDataToLocalStorage } from "../utils/localStorage";
import { getReturnOrderId } from "../utils/cookies";
import { setJWTToken, unsetJWTToken } from "../utils/login";

const finishAuthorization = (response) => {
	const { user, token } = response.data;
	const { id, firstName, lastName, email } = user;
	saveAuthDataToLocalStorage(id, email, firstName, lastName, token);
	setJWTToken();
	const returnTableId = getReturnOrderId();
	navigate(returnTableId ? LINKS.ORDER + returnTableId : LINKS.HOMEPAGE);
	LogRocket.identify(uuid().toString(), {
		name: `${firstName} ${lastName}`,
		email,
	});
	return response;
};

const authorizationFailed = (response) => {
	removeAuthDataFromLocalStorage();
	unsetJWTToken();
	navigate(LINKS.HOMEPAGE);
	return response;
};

export const authorizeTest = () => axios.get(API_LINKS.AUTHORIZE_TEST).then(finishAuthorization).catch(authorizationFailed);

export const authorizeGoogle = (queryString) =>
	axios
		.get(API_LINKS.AUTHORIZE_GOOGLE_API + queryString)
		.then(finishAuthorization)
		.catch(authorizationFailed);

export const authorizeFacebook = (queryString) =>
	axios
		.get(API_LINKS.AUTHORIZE_FACEBOOK_API + queryString)
		.then(finishAuthorization)
		.catch(authorizationFailed);
