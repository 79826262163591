/* eslint-disable */
import * as React from "react";

const ReceiptIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg height={18} viewBox="0 0 18 18" width={18} xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M0 0h18v18H0z" data-name="Path 3831" fill="none" />
        <g data-name="Group 5880">
            <path
                d="M14.625 2.625L13.5 1.5l-1.125 1.125L11.25 1.5l-1.125 1.125L9 1.5 7.875 2.625 6.75 1.5 5.625 2.625 4.5 1.5V12H2.25v2.25A2.247 2.247 0 004.5 16.5h9a2.247 2.247 0 002.25-2.25V1.5zM11.25 15H4.5a.752.752 0 01-.75-.75v-.75h7.5zm3-.75a.75.75 0 01-1.5 0V12H6V3.75h8.25z"
                data-name="Path 3832"
            />
            <path d="M7 5h6v1H7z" data-name="Rectangle 3297" />
            <path d="M7 7h6v1H7z" data-name="Rectangle 3299" />
        </g>
    </svg>
);

export default ReceiptIcon;
