export const LINKS = {
	HOMEPAGE: '/',
	NOT_FOUND: '/404',
	ORDER: '/order?orderId=',
	TABLE: '/table?tableId=',
	RATING: '/rating?orderId=',
	SCAN_QR: '/scan-qr',
	ENTER_CODE: '/enter-code',
	BILL_ERROR: '/bill-error',
	RESTAURANTS: '/restaurants',
	RESTAURANT: '/restaurant',
	MENU: '/menu',
	RATE_US: '/rate-us',
	RATE_US_SEND: '/rate-us-send',
	CONTACT_US: '/contact-us',
	CONTACT_US_SEND: '/contact-us-send',
	CONTACT_US_FAILED: '/contact-us-failed',
	COMMENT_AND_RATING_SEND: '/comment-and-rating-send',
	PROPOSE_RESTAURANT: '/propose-restaurant',
	PROPOSE_RESTAURANT_SEND: '/propose-restaurant-send',
	PAYMENT_STATUS: '/payment-status?paymentId=',
	PAYMENT_SUCCESS: '/payment-success?batchId=',
	PAYMENT_FAILED: '/payment-failed',
	GENERAL_POLICY: '/general-policy',
	PRIVACY_POLICY: '/privacy-policy',
	LOGIN: '/login',
	ORDER_HISTORY: '/order-history',
};

const restApiPay = process.env.GATSBY_REST_PAY_API_URL || 'https://test.api.pay.restimo.com';

export const API_LINKS = {
	// AUTH
	AUTHORIZE_GOOGLE: `${restApiPay}/auth/gplus`,
	AUTHORIZE_GOOGLE_API: `${restApiPay}/auth/gplus/ended`,
	AUTHORIZE_FACEBOOK: `${restApiPay}/auth/facebook`,
	AUTHORIZE_FACEBOOK_API: `${restApiPay}/auth/facebook/ended`,
	AUTHORIZE_TEST: `${restApiPay}/auth/test`,

	// RATING
	RATE_APP: `${restApiPay}/rate-us`,
	ORDER_RATINGS: `${restApiPay}/orders/ratings/`,

	// ORDER
	FETCH_ORDER_BY_USER: `${restApiPay}/orders/byUser/`,
	FETCH_ORDER_BY_ID: `${restApiPay}/orders/byId/`,
	FETCH_ORDER_BY_TABLE: `${restApiPay}/orders/byTableId/`,
	FETCH_ORDER_BY_CODE: `${restApiPay}/orders/byTableCode/`,
	SEND_DIVIDE_BILL: `${restApiPay}/orders/divide/`,
	PLACE_ORDER: `${restApiPay}/orders/createOrder/`,

	// MAIL
	SEND_EMAIL: `${restApiPay}/contact`,
	PROPOSE_RESTAURANT: `${restApiPay}/proposeRestaurant`,

	// PAYMENT
	ADD_PAYMENT: `${restApiPay}/payments/`,
	PAYMENT_STATUS: `${restApiPay}/payments/statuses/`,

	// RESTAURANT
	FETCH_RESTAURANTS: `${restApiPay}/restaurants/`,

	// TABLE
	FETCH_TABLE_DETAILS: `${restApiPay}/restaurantTables/`,

	// MENU
	FETCH_MENU_DETAILS: `${restApiPay}/restaurantMenu/`,
};
