const createRipple = (event, mode) => {
	const button = event.target.closest('a, button, .react-rater-star, .ripple-dock');

	const circle = document.createElement('div');
	const diameter = 30;
	const radius = diameter / 2;
	const rect = button.getBoundingClientRect();

	circle.style.width = `${diameter}px`;
	circle.style.height = `${diameter}px`;
	circle.style.left = `${event.clientX - rect.left - radius}px`;
	circle.style.top = `${event.clientY - rect.top - radius}px`;
	circle.classList.add('ripple');

	if (mode) {
		circle.classList.add(mode);
	}

	const [ripple] = document.getElementsByClassName('ripple');
	if (ripple) {
		ripple.remove();
	}

	button.appendChild(circle);
};

export default createRipple;
