import { getOrderRating, rateOrder } from "../services/contactService";

const initialState = {
	body: {
		foodRating: 0,
		serviceRating: 0,
		rating: 0,
		comment: "",
	},
	loading: false,
	error: false,
	editing: true,
};

const CHANGE_RATING_VALUE = "CHANGE_RATING_VALUE";
const RATE_ORDER = "RATE_ORDER";
const GET_ORDER_RATING = "GET_ORDER_RATING";
const CLEAR_RATING_AND_COMMENT_FORM = "CLEAR_RATING_AND_COMMENT_FORM";

export const rateOrderAction = (orderId, foodRating, serviceRating, rating, comment) => ({
	type: RATE_ORDER,
	payload: rateOrder(orderId, foodRating, serviceRating, rating, comment),
});
export const getOrderRatingAction = (orderId) => ({
	type: GET_ORDER_RATING,
	payload: getOrderRating(orderId),
});
export const changeRatingFormValue = (name, value) => ({
	type: CHANGE_RATING_VALUE,
	payload: {
		name,
		value,
	},
});
export const clearRatingAndCommentFormAction = () => ({
	type: CLEAR_RATING_AND_COMMENT_FORM,
});

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_RATING_AND_COMMENT_FORM:
			return {
				...initialState,
			};
		case `${RATE_ORDER}_PENDING`:
			return {
				...state,
				loading: true,
			};
		case `${RATE_ORDER}_REJECTED`:
			return {
				...state,
				loading: false,
				error: true,
			};
		case `${RATE_ORDER}_FULFILLED`:
			return {
				...state,
				loading: false,
				error: false,
			};
		case `${GET_ORDER_RATING}_PENDING`:
			return {
				...state,
				loading: true,
			};
		case `${GET_ORDER_RATING}_REJECTED`:
			return {
				...state,
				loading: false,
				error: true,
			};
		case `${GET_ORDER_RATING}_FULFILLED`:
			return {
				...state,
				loading: false,
				error: false,
				body: {
					foodRating: action.payload.data.foodRating || 0,
					serviceRating: action.payload.data.serviceRating || 0,
					rating: action.payload.data.rating || 0,
					comment: action.payload.data.comment || "",
				},
				editing: !!action.payload.data.rating,
			};
		case CHANGE_RATING_VALUE:
			return {
				...state,
				body: {
					...state.body,
					[action.payload.name]: action.payload.value,
				},
			};
		default:
			return state;
	}
};
