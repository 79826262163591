export const theme = {
	constants: {
		headerHeight: "80px",
	},
	colors: {
		brandBlue: "#8DD1F3",
		brandPurple: "#6B257B",
		fontGray: "#646464",
		fontBlack: "#1E1E1C",
		fontRed: "#CF4E4E",
		gray: "#CFCFCF",
		lightGray: "#ECECEC",
		borderGray: "#BCBCBC",
		darkBorderGray: "#707070",
		disabledGray: "#A5A5A5",
	},
};
