import { authorizeFacebook, authorizeGoogle, authorizeTest } from '../services/userService';
import {
	getUserEmailAddress, getUserFirstName, getUserId, getUserLastName,
} from '../utils/localStorage';
import { UserStateTypesInterface } from './@types/user/userStateTypes.interface';

const initialState: UserStateTypesInterface = {
	loading: false,
	error: false,
	user: {
		id: getUserId(),
		email: getUserEmailAddress(),
		firstName: getUserFirstName(),
		lastName: getUserLastName(),
	},
	token: null,
};

const GOOGLE_AUTHORIZE = 'GOOGLE_AUTHORIZE';
const FACEBOOK_AUTHORIZE = 'FACEBOOK_AUTHORIZE';
const LOGOUT_USER = 'LOGOUT_USER';

export const authorizeTestAction = () => ({
	type: GOOGLE_AUTHORIZE,
	payload: authorizeTest(),
});

export const authorizeGoogleAction = (queryString: string) => ({
	type: GOOGLE_AUTHORIZE,
	payload: authorizeGoogle(queryString),
});

export const authorizeFacebookAction = (queryString: string) => ({
	type: FACEBOOK_AUTHORIZE,
	payload: authorizeFacebook(queryString),
});

export const logoutUserAction = () => ({
	type: LOGOUT_USER,
});

export default (state = initialState, action) => {
	switch (action.type) {
		case `${GOOGLE_AUTHORIZE}_PENDING`:
			return {
				...initialState,
				loading: true,
				error: false,
			};
		case `${GOOGLE_AUTHORIZE}_REJECTED`:
			return {
				...initialState,
				loading: false,
				error: true,
			};
		case `${GOOGLE_AUTHORIZE}_FULFILLED`:
			return {
				...state,
				loading: false,
				error: false,
				user: action.payload.data.user,
				token: action.payload.data.token,
			};
		case `${FACEBOOK_AUTHORIZE}_PENDING`:
			return {
				...initialState,
				loading: true,
				error: false,
			};
		case `${FACEBOOK_AUTHORIZE}_REJECTED`:
			return {
				...initialState,
				loading: false,
				error: true,
			};
		case `${FACEBOOK_AUTHORIZE}_FULFILLED`:
			return {
				...state,
				loading: false,
				error: false,
				user: action.payload.data.user,
				token: action.payload.data.token,
			};
		case LOGOUT_USER:
			return initialState;
		default:
			return state;
	}
};
