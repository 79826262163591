import axios from 'axios';
import { API_LINKS } from '../constants/links';
import { isNotBuildPhase } from '../utils/localStorage';

export const generatePaymentUrl = (orderId, clientEnteredPrice, tip, paymentType, nip) => {
	console.log(orderId, clientEnteredPrice, tip, paymentType, nip);
	if (!orderId || !clientEnteredPrice || !paymentType) {
		throw new Error('No valid data passed to function');
	}
	return axios.post(API_LINKS.ADD_PAYMENT, {
		paymentType,
		orderId,
		clientEnteredPrice,
		tip,
		nip,
	});
};

export const getPaymentStatus = (serviceId, orderId) => axios.get(`${API_LINKS.PAYMENT_STATUS}${serviceId}/${orderId}`);

export const redirectToPaymentProvider = (redirectUrl) => {
	if (isNotBuildPhase && redirectUrl) {
		window.location.href = redirectUrl;
	}
};
