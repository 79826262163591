import { fetchOrderHistory } from "../services/orderHistoryService";

const initialState = {
	loading: false,
	error: false,
	data: [],
};

const FETCH_ORDER_HISTORY = "FETCH_ORDER_HISTORY";

export const fetchOrderHistoryAction = (userId) => ({
	type: FETCH_ORDER_HISTORY,
	payload: fetchOrderHistory(userId),
});

export default (state = initialState, action) => {
	switch (action.type) {
		case `${FETCH_ORDER_HISTORY}_PENDING`:
			return {
				...initialState,
				loading: true,
				error: false,
			};
		case `${FETCH_ORDER_HISTORY}_REJECTED`:
			return {
				...initialState,
				loading: false,
				error: true,
			};
		case `${FETCH_ORDER_HISTORY}_FULFILLED`:
			return {
				...state,
				loading: false,
				error: false,
				data: action.payload.data,
			};
		default:
			return state;
	}
};
