import axios from 'axios';
import { PlaceOrderInterface } from '@restimo/restpay-types';
import { API_LINKS } from '../constants/links';

export const getOrderDetailsById = (orderId) => axios.get(`${API_LINKS.FETCH_ORDER_BY_ID}${orderId}`);
export const getOrdersByTableCode = (tableCode) => axios.get(`${API_LINKS.FETCH_ORDER_BY_CODE}${tableCode}`);
export const getOrdersByTableId = (tableId) => axios.get(`${API_LINKS.FETCH_ORDER_BY_TABLE}${tableId}`);
export const callAWaiter = (orderId) => axios.post(`${API_LINKS.FETCH_ORDER_BY_ID}${orderId}/callAWaiter`);
export const sendDivideBill = (orderId, positions) => axios.post(`${API_LINKS.SEND_DIVIDE_BILL}${orderId}`, positions);
export const placeOrder = (
	localizationId: string,
	restaurantId: string,
	tableId: string,
	placeOrder: PlaceOrderInterface,
	remarks: string,
	isPickup: boolean,
) =>
	axios.post(`${API_LINKS.PLACE_ORDER}`, {
		order: placeOrder,
		localizationId,
		restaurantId,
		tableId,
		remarks,
		isPickup,
	});
