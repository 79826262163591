import { getAppRating, rateApp } from "../services/contactService";

const initialState = {
	rating: null,
	loading: false,
	error: false,
	fulfilled: false,
};

const CHANGE_APP_RATING_FORM_VALUE = "CHANGE_APP_RATING_FORM_VALUE";
const RATE_APP = "RATE_APP";
const GET_APP_RATING = "GET_APP_RATING";
const CLEAR_APP_RATING_FORM = "CLEAR_APP_RATING_FORM";

export const rateAppAction = (rating) => ({
	type: RATE_APP,
	payload: rateApp(rating),
});
export const getAppRatingAction = () => ({
	type: GET_APP_RATING,
	payload: getAppRating(),
});
export const changeAppRatingFormValue = (value) => ({
	type: CHANGE_APP_RATING_FORM_VALUE,
	payload: value,
});
export const clearAppRatingAction = () => ({
	type: CLEAR_APP_RATING_FORM,
});

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_APP_RATING_FORM:
			return {
				...initialState,
			};
		case `${RATE_APP}_PENDING`:
			return {
				...state,
				loading: true,
				error: false,
				fulfilled: false,
			};
		case `${RATE_APP}_REJECTED`:
			return {
				...state,
				loading: false,
				error: true,
				fulfilled: false,
			};
		case `${RATE_APP}_FULFILLED`:
			return {
				...state,
				loading: false,
				error: false,
				fulfilled: true,
			};
		case `${GET_APP_RATING}_PENDING`:
			return {
				...state,
				loading: true,
			};
		case `${GET_APP_RATING}_REJECTED`:
			return {
				...state,
				loading: false,
				error: true,
			};
		case `${GET_APP_RATING}_FULFILLED`:
			return {
				...state,
				loading: false,
				error: false,
				rating: action.payload.data.rating || null,
			};
		case CHANGE_APP_RATING_FORM_VALUE:
			return {
				...state,
				rating: action.payload,
			};
		default:
			return state;
	}
};
