/* eslint-disable */
import * as React from "react";

const FavouriteIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg height={18} viewBox="0 0 18 18" width={18} xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M0 0h18v18H0z" data-name="Path 3805" fill="none" />
        <path
            d="M12.15 3A4.262 4.262 0 009 4.407 4.262 4.262 0 005.85 3 3.741 3.741 0 002 6.7c0 2.544 2.38 4.617 5.985 7.767L9 15.35l1.015-.888C13.62 11.319 16 9.246 16 6.7A3.741 3.741 0 0012.15 3zM9.07 13.466l-.07.067-.07-.067C5.6 10.565 3.4 8.647 3.4 6.7a2.348 2.348 0 012.45-2.354 2.741 2.741 0 012.5 1.588h1.308a2.723 2.723 0 012.492-1.588A2.348 2.348 0 0114.6 6.7c0 1.947-2.2 3.865-5.53 6.766z"
            data-name="Path 3806"
            fill="#646464"
        />
    </svg>
);

export default FavouriteIcon;
