import Cookies from "universal-cookie";
import * as moment from "moment";

const cookies = new Cookies();

const IOS_A2HS_COOKIE_NAME = "IOS_A2HS_SHOWN";
const CAMERA_ERROR = "CAMERA_ERROR";
const LOGIN_REDIRECTED = "LOGIN_REDIRECTED";

const RETURN_ORDER_ID = "RETURN_ORDER_ID";
const RETURN_PRICE = "RETURN_PRICE";
const RETURN_TIP = "RETURN_TIP";
const RETURN_PAYMENT_TYPE = "RETURN_PAYMENT_TYPE";
const RETURN_INVOICE_NIP = "RETURN_INVOICE_NIP";

const expires5Min = () => ({
	expires: moment(new Date()).add(5, "minutes").toDate(),
});
const expires1Week = () => ({
	expires: moment(new Date()).add(1, "week").toDate(),
});
const expires1Year = () => ({
	expires: moment(new Date()).add(1, "year").toDate(),
});
const expires1Day = () => ({
	expires: moment(new Date()).add(1, "day").toDate(),
});

export const isCameraError = () => cookies.get(CAMERA_ERROR) && cookies.get(CAMERA_ERROR) === "true";
export const setCameraError = () => cookies.set(CAMERA_ERROR, "true", expires1Year());
export const clearCameraError = () => cookies.remove(CAMERA_ERROR);

export const wasIOSA2HSShown = () => cookies.get(IOS_A2HS_COOKIE_NAME) && cookies.get(IOS_A2HS_COOKIE_NAME) === "true";
export const setIOSA2HSShown = () => cookies.set(IOS_A2HS_COOKIE_NAME, "true", expires1Week());

export const setLoginRedirected = () => cookies.set(LOGIN_REDIRECTED, "true", expires1Day());
export const isLoginRedirected = () => cookies.get(LOGIN_REDIRECTED) && cookies.get(LOGIN_REDIRECTED) === "true";

export const setReturnOrderId = (value) => cookies.set(RETURN_ORDER_ID, value, expires5Min());
export const setReturnPrice = (value) => cookies.set(RETURN_PRICE, value, expires5Min());
export const setReturnTip = (value) => cookies.set(RETURN_TIP, value, expires5Min());
export const setReturnPaymentType = (value) => cookies.set(RETURN_PAYMENT_TYPE, value, expires5Min());
export const setInvoiceNip = (value) => cookies.set(RETURN_INVOICE_NIP, value, expires5Min());

export const getReturnOrderId = () => (cookies.get(RETURN_ORDER_ID) ? cookies.get(RETURN_ORDER_ID) : null);
export const getReturnPrice = () => (cookies.get(RETURN_PRICE) ? cookies.get(RETURN_PRICE) : null);
export const getReturnTip = () => (cookies.get(RETURN_TIP) ? cookies.get(RETURN_TIP) : null);
export const getReturnPaymentType = () => (cookies.get(RETURN_PAYMENT_TYPE) ? cookies.get(RETURN_PAYMENT_TYPE) : null);
export const getInvoiceNip = () => (cookies.get(RETURN_INVOICE_NIP) ? cookies.get(RETURN_INVOICE_NIP) : null);

export const setCookiesBeforeLogin = (orderId, price, tip, paymentType, nip) => {
	setReturnOrderId(orderId);
	setReturnPrice(price);
	setReturnTip(tip);
	setReturnPaymentType(paymentType);
	setInvoiceNip(nip);
};

export const getCookiesBeforeLogin = () => {
	const orderId = getReturnOrderId();
	const price = getReturnPrice();
	const tip = getReturnTip();
	const paymentType = getReturnPaymentType();
	const nip = getInvoiceNip();
	return {
		orderId,
		price,
		tip,
		paymentType,
		nip,
	};
};

export const clearCookiesBeforeLogin = () => {
	cookies.remove(RETURN_ORDER_ID, {
		path: "/",
	});
	cookies.remove(RETURN_PRICE, {
		path: "/",
	});
	cookies.remove(RETURN_TIP, {
		path: "/",
	});
	cookies.remove(RETURN_PAYMENT_TYPE, {
		path: "/",
	});
	cookies.remove(RETURN_INVOICE_NIP, {
		path: "/",
	});
};
