import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { Helmet } from 'react-helmet';
import './layout.css';
import { isMobile } from 'react-device-detect';
import { datadogRum } from '@datadog/browser-rum';
import { theme } from '../constants/theme';
import { LINKS } from '../constants/links';
import i18n from '../utils/i18n';
import { isNotBuildPhase, isUserLogged } from '../utils/localStorage';
import { getAppRatingAction } from '../state/rateUs.reducer';
import AppText from './common/texts/appText';
import PortraitOrientationLock from './common/portraitOrientationLock';
import Header from './header/header';
import UpdateAvailable from './updateAvaliable';
import Popup from './common/popup';

const StyledCookieConsentHeader = styled.h1`
	color: #fff;
	font-size: 1.6rem;
	font-weight: 600;
	line-height: 1.6rem;
	margin: 0 0 25px;
`;
const StyledCookieConsentText = styled(AppText)`
	color: #fff;
	margin: 0;
	text-align: justify;

	a {
		color: #fff;
		font-weight: 900;
	}
`;

if (isNotBuildPhase && (process.env.GATSBY_REST_PAY_ENV === 'staging' || process.env.GATSBY_REST_PAY_ENV === 'production')) {
	datadogRum.init({
		applicationId: '4441c5b9-8805-46d9-9314-8f99952b3de7',
		clientToken: 'pub14906b03f1e582bc7156f8f853046608',
		site: 'datadoghq.eu',
		service: 'restpay',
		sampleRate: 100,
		env: process.env.GATSBY_REST_PAY_ENV,
		trackInteractions: true,
		defaultPrivacyLevel: 'allow',
	});
}

const Layout = ({ children }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const rateUs = useSelector((state) => state.rateUs);
	const [isPortrait, setIsPortrait] = useState(true);
	const {
		customLogoSrc, customLogoUrl, backTo, topOptions, withoutHeader,
	} = useSelector((state) => state.layout);
	const { user } = useSelector((state) => state.user);

	const checkIsPortrait = () => {
		if (!isNotBuildPhase) {
			return;
		}
		if (isMobile) {
			const screenOrientation = window.innerWidth > window.innerHeight ? 90 : 0;
			setIsPortrait(screenOrientation === 0);
		}
	};

	useEffect(() => {
		if (isNotBuildPhase && (process.env.GATSBY_REST_PAY_ENV === 'staging' || process.env.GATSBY_REST_PAY_ENV === 'production')) {
			datadogRum.startSessionReplayRecording();
		}
		checkIsPortrait();
		if (isNotBuildPhase) {
			window.addEventListener('orientationchange', checkIsPortrait);
			window.addEventListener('resize', checkIsPortrait);
		}
		if (isUserLogged() && !rateUs.rating) {
			setTimeout(() => {
				dispatch(getAppRatingAction());
			}, 500);
		}
	}, []);

	useEffect(() => {
		if (isNotBuildPhase) {
			if (user?.id) {
				datadogRum.setUser({
					id: user?.id,
					name: `${user?.firstName} ${user?.lastName}`,
					email: user?.email,
				});
			} else {
				datadogRum.removeUser();
			}
		}
	}, [user]);

	return (
		<ThemeProvider theme={theme}>
			<I18nextProvider i18n={i18n}>
				<>
					<Helmet
						link={[
							{
								rel: 'icon',
								type: 'image/png',
								sizes: '16x16',
								href: '/favicon-16x16.png',
							},
							{
								rel: 'icon',
								type: 'image/png',
								sizes: '32x32',
								href: '/favicon-32x32.png',
							},
							{
								rel: 'apple-touch-icon',
								type: 'image/png',
								href: '/apple-touch-icon.png',
							},
							{
								rel: 'apple-touch-startup-image',
								media: '(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)',
								href: '/iphone6_splash.png',
							},
							{
								rel: 'apple-touch-startup-image',
								media: '(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)',
								href: '/iphone6_splash.png',
							},
							{
								rel: 'apple-touch-startup-image',
								media: '(device-width: 621px) and (device-height: 1104px) and (-webkit-device-pixel-ratio: 3)',
								href: '/iphoneplus_splash.png',
							},
							{
								rel: 'apple-touch-startup-image',
								media: '(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)',
								href: '/iphonex_splash.png',
							},
							{
								rel: 'apple-touch-startup-image',
								media: '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)',
								href: '/iphonexr_splash.png',
							},
							{
								rel: 'apple-touch-startup-image',
								media: '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)',
								href: '/iphonexsmax_splash.png',
							},
							{
								rel: 'apple-touch-startup-image',
								media: '(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)',
								href: '/ipad_splash.png',
							},
							{
								rel: 'apple-touch-startup-image',
								media: '(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)',
								href: '/ipadpro1_splash.png',
							},
							{
								rel: 'apple-touch-startup-image',
								media: '(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2)',
								href: '/ipadpro3_splash.png',
							},
							{
								rel: 'apple-touch-startup-image',
								media: '(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)',
								href: '/ipadpro2_splash.png',
							},
						]}
					>
						<meta
							content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
							name="viewport"
						/>
						<meta
							content="yes"
							name="apple-mobile-web-app-capable"
						/>
						<meta
							content="#ECECEC"
							name="theme-color"
						/>
						<meta
							content="yes"
							name="mobile-web-app-capable"
						/>
						<meta
							content="Restpay"
							name="apple-mobile-web-app-title"
						/>
						<meta
							content="yes"
							name="apple-mobile-web-app-capable"
						/>
						<meta
							content="default"
							name="apple-mobile-web-app-status-bar-style"
						/>
						<meta
							content="no"
							name="msapplication-tap-highlight"
						/>
						<meta
							content="yes"
							name="full-screen"
						/>
						<meta
							content="application"
							name="browsermode"
						/>
						<meta
							content="disable"
							name="nightmode"
						/>
						<meta
							content="fitscreen"
							name="layoutmode"
						/>
						<meta
							content="force"
							name="imagemode"
						/>
						<meta
							content="portrait"
							name="screen-orientation"
						/>
						<meta
							content="Restpay"
							property="og:title"
						/>
						<meta
							content={t('social.description')}
							property="og:description"
						/>
						<meta
							content="https://restpay.fra1.cdn.digitaloceanspaces.com/socials/facebook_share.png"
							property="og:image"
						/>
						<meta
							content="https://pay.restimo.com"
							property="og:url"
						/>
						<meta
							content="Restpay"
							property="og:site_name"
						/>
						<meta
							content="Restpay"
							name="twitter:title"
						/>
						<meta
							content={t('social.description')}
							name="twitter:description"
						/>
						<meta
							content="https://restpay.fra1.cdn.digitaloceanspaces.com/socials/twitter_share.png"
							name="twitter:image"
						/>
						<meta
							content="summary"
							name="twitter:card"
						/>
						<meta
							content="2410689199048251"
							property="fb:app_id"
						/>
					</Helmet>
					{isPortrait && (
						<>
							{!withoutHeader && (
								<Header
									backTo={backTo}
									customLogoSrc={customLogoSrc}
									customLogoUrl={customLogoUrl}
									siteTitle="Restpay"
									topOptions={topOptions}
								/>
							)}
							<main className="height100">{children}</main>
							<CookieConsent
								buttonStyle={{
									background: '#fff',
									color: theme.colors.brandPurple,
									borderRadius: '23px',
									fontSize: '1rem',
									margin: '0 25px 25px 0',
									padding: '0 34px',
									lineHeight: '35px',
									fontWeight: '700',
									textTransform: 'capitalize',
								}}
								buttonText={t('common.privacyPolicyAccept')}
								contentStyle={{
									padding: '25px 20px',
									margin: '0',
									flex: 'auto',
								}}
								cookieName="COOKIES_POPUP_SHOWN"
								location="bottom"
								style={{
									width: '100%',
									display: 'flex',
									justifyContent: 'flex-end',
									background: theme.colors.brandPurple,
								}}
							>
								<StyledCookieConsentHeader>{t('common.privacyPolicyHeading')}</StyledCookieConsentHeader>
								<StyledCookieConsentText>
									{t('common.privacyPolicyText')}
									<a
										href={LINKS.PRIVACY_POLICY}
										rel="noreferrer"
										target="_blank"
									>
										{t('common.privacyPolicyLink')}
									</a>
									.
								</StyledCookieConsentText>
							</CookieConsent>
						</>
					)}
					{!isPortrait && <PortraitOrientationLock />}
					<UpdateAvailable />
					<Popup cookiesAccepted={Cookies.get('COOKIES_POPUP_SHOWN')} />
				</>
			</I18nextProvider>
		</ThemeProvider>
	);
};

export default Layout;

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};
