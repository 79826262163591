import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { translations } from "../translations/translations";

i18n.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: "pl-PL",
		debug: false,
		interpolation: {
			escapeValue: false,
		},
		resources: {
			"pl-PL": translations.pl,
			"en-EN": translations.en,
			"de-DE": translations.de,
		},
	});

export default i18n;
