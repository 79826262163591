import { sendDivideBill } from "../services/orderService";

const initialState = {
	id: undefined,
	loading: false,
	error: false,
	success: false,
	total: 0,
	net: 0,
	tax: 0,
	positions: [],
};
const ADD_POSITION = "ADD_POSITION";
const REMOVE_POSITION = "REMOVE_POSITION";
const CLEAR_NEW_ORDER = "CLEAR_NEW_ORDER";
const SEND_DIVIDE_BILL = "SEND_DIVIDE_BILL";

export const addPositionAction = (position) => ({
	type: ADD_POSITION,
	payload: position,
});

export const removePositionAction = (position) => ({
	type: REMOVE_POSITION,
	payload: position,
});

export const clearNewOrderAction = () => ({
	type: CLEAR_NEW_ORDER,
});

export const sendDivideBillAction = (orderId, positions) => ({
	type: SEND_DIVIDE_BILL,
	payload: sendDivideBill(orderId, positions),
});

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_NEW_ORDER:
			return initialState;
		case ADD_POSITION:
			return {
				...state,
				total: [...state.positions, action.payload].reduce((prev, curr) => prev + curr.count * curr.price, 0),
				net: [...state.positions, action.payload].reduce(
					(prev, curr) => prev + (curr.count * curr.price * (100 - curr.tax)) / 100,
					0
				),
				tax: [...state.positions, action.payload].reduce((prev, curr) => prev + (curr.count * curr.price * curr.tax) / 100, 0),
				positions: [...state.positions, action.payload],
			};
		case REMOVE_POSITION:
			return {
				...state,
				total: state.positions
					.filter((pos) => pos.id !== action.payload.id)
					.reduce((prev, curr) => prev + curr.count * curr.price, 0),
				net: state.positions
					.filter((pos) => pos.id !== action.payload.id)
					.reduce((prev, curr) => prev + (curr.count * curr.price * (100 - curr.tax)) / 100, 0),
				tax: state.positions
					.filter((pos) => pos.id !== action.payload.id)
					.reduce((prev, curr) => prev + (curr.count * curr.price * curr.tax) / 100, 0),
				positions: state.positions.filter((pos) => pos.id !== action.payload.id),
			};
		case `${SEND_DIVIDE_BILL}_PENDING`:
			return {
				...state,
				loading: true,
				error: false,
				success: false,
			};
		case `${SEND_DIVIDE_BILL}_REJECTED`:
			return {
				...state,
				loading: false,
				error: true,
				success: false,
			};
		case `${SEND_DIVIDE_BILL}_FULFILLED`:
			return {
				...state,
				id: action.payload.data.id,
				loading: false,
				error: false,
				success: true,
			};
		default:
			return state;
	}
};
