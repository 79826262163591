import { v4 as uuid } from 'uuid';
import { PlaceOrderInterface } from 'restpay-api-lib/lib/@types/_interfaces/orders/placeOrder/placeOrder.interface';
import { placeOrder } from '../services/orderService';
import { CartStateTypesInterface } from './@types/cart/cartStateTypes.interface';
import { ItemInOrderInterface } from './@types/cart/itemInOrder.interface';
import { ItemInCartInterface } from './@types/cart/itemInCart.interface';

const initialState: CartStateTypesInterface = {
	isPickup: false,
	orders: [],
	ordersInCurrentRestaurant: [],
};

const CHANGE_PICKUP_MODE = 'CHANGE_PICKUP_MODE';
const PLACE_ORDER = 'PLACE_ORDER';
const ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART';
const SET_CURRENT_RESTAURANT_ITEMS = 'SET_CURRENT_RESTAURANT_ITEMS';
const CLEAR_CURRENT_RESTAURANT_ITEMS = 'CLEAR_CURRENT_RESTAURANT_ITEMS';
const SET_ITEM_QUANTITY = 'SET_ITEM_QUANTITY';
const REMOVE_ITEM_FROM_CART = 'REMOVE_ITEM_FROM_CART';

export const changePickupModeAction = (isPickup: boolean) => ({
	type: CHANGE_PICKUP_MODE,
	payload: isPickup,
});

export const placeOrderAction = (localizationId: string, restaurantId: string, tableId: string, placeOrderBody: PlaceOrderInterface[], remarks: string) => ({
	type: PLACE_ORDER,
	payload: placeOrder(localizationId, restaurantId, tableId, placeOrderBody, remarks),
});

export const addItemToCartAction = (cartItem: ItemInCartInterface) => ({
	type: ADD_ITEM_TO_CART,
	payload: {
		...cartItem,
		id: uuid(),
	},
});

export const setCurrentRestaurantOrdersAction = (orders: ItemInOrderInterface[]) => ({
	type: SET_CURRENT_RESTAURANT_ITEMS,
	payload: orders,
});

export const clearCurrentRestaurantItemsAction = (restaurantId: string) => ({
	type: CLEAR_CURRENT_RESTAURANT_ITEMS,
	payload: restaurantId,
});

export const setItemQuantityAction = (itemId: string, quantity: number) => ({
	type: SET_ITEM_QUANTITY,
	payload: {
		itemId,
		quantity,
	},
});

export const removeItemFromCartAction = (itemId: string) => ({
	type: REMOVE_ITEM_FROM_CART,
	payload: itemId,
});

export default (state = initialState, action): CartStateTypesInterface => {
	switch (action.type) {
		case CHANGE_PICKUP_MODE:
			return {
				...state,
				isPickup: action.payload,
			};
		case ADD_ITEM_TO_CART:
			return {
				...state,
				orders: [
					...state.orders,
					{
						...action.payload,
					},
				],
			};
		case SET_CURRENT_RESTAURANT_ITEMS:
			return {
				...state,
				ordersInCurrentRestaurant: action.payload,
			};
		case CLEAR_CURRENT_RESTAURANT_ITEMS:
			return {
				...state,
				orders: state.orders.filter((order) => order.restaurantId !== action.payload),
				ordersInCurrentRestaurant: [],
			};
		case SET_ITEM_QUANTITY:
			return {
				...state,
				orders: state.orders.map((item) =>
					(item.id === action.payload.itemId ? {
						...item,
						quantity: action.payload.quantity,
						formData: {
							...item.formData,
							quantity: action.payload.quantity,
						},
					} : item)),
				ordersInCurrentRestaurant: state.ordersInCurrentRestaurant.map((item) =>
					(item.id === action.payload.itemId ? {
						...item,
						quantity: action.payload.quantity,
						formData: {
							...item.formData,
							quantity: action.payload.quantity,
						},
					} : item)),
			};
		case REMOVE_ITEM_FROM_CART:
			const ordersIndex = state.orders.findIndex((item) => item.id === action.payload);
			const currentOrdersIndex = state.ordersInCurrentRestaurant.findIndex((item) => item.id === action.payload);
			return {
				...state,
				orders: [...state.orders.slice(0, ordersIndex), ...state.orders.slice(ordersIndex + 1)],
				ordersInCurrentRestaurant: [
					...state.orders.slice(0, currentOrdersIndex),
					...state.orders.slice(currentOrdersIndex + 1),
				],
			};
		default:
			return state;
	}
};
