import React, { FC } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CloseButtonIcon from '../../../icons/closeButtonIcon';
import { GlobalState } from '../../../state/@types/redux.interface';
import { setDrawerAction, setLayoutAction } from '../../../state/layout.reducer';
import ArrowIcon from '../../../icons/arrowIcon';

const UsernameAndCloseButtonSection = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;
const UserNameText = styled.div`
    font-size: 18px;
    font-weight: bold;
`;
const CloseButtonWrapper = styled.div`
  cursor: pointer;
`;
const MarginSection = styled.div<{marginBottom: number}>`
  margin-bottom: ${(props) => props.marginBottom}px;
`;
const UserEmailText = styled.div`
    color: #646464;
    font-size: 12px;
`;

interface DrawerUpperSectionInterface {
    areSettingsOpened: boolean;
    setAreSettingsOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

const DrawerUpperSection: FC<DrawerUpperSectionInterface> = ({ areSettingsOpened, setAreSettingsOpened }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { user } = useSelector((state: GlobalState) => state.user);

	const closeDrawer = () => {
		dispatch(setDrawerAction(false));
	};

	return (
		<div>
			{!areSettingsOpened ? (
				<>
					<UsernameAndCloseButtonSection>
						<UserNameText>
							{t('common.hello')}
							{' '}
							{`${user.firstName} ${user.lastName}`}
							!
						</UserNameText>
						<CloseButtonWrapper>
							<CloseButtonIcon
								height={20}
								onClick={closeDrawer}
								width={20}
							/>
						</CloseButtonWrapper>
					</UsernameAndCloseButtonSection>
					<MarginSection marginBottom={80}>
						<UserEmailText>{user.email}</UserEmailText>
					</MarginSection>
				</>
			) : (
				<>
					<UsernameAndCloseButtonSection>
						<UserNameText>
							{t('common.settings')}
						</UserNameText>
						<CloseButtonWrapper>
							<ArrowIcon
								fill="#6b257b"
								height={20}
								onClick={() => setAreSettingsOpened(false)}
								style={{ transform: 'rotate(180deg)' }}
								width={20}
							/>
						</CloseButtonWrapper>
					</UsernameAndCloseButtonSection>
					<MarginSection marginBottom={97} />
				</>
			)}
		</div>

	);
};
export default DrawerUpperSection;
