import axios from "axios";
import { navigate } from "gatsby";
import { API_LINKS, LINKS } from "../constants/links";

export const sendEmail = (name, title, body, email) =>
	axios.post(API_LINKS.SEND_EMAIL, {
		name,
		title,
		body,
		email,
	});

export const proposeRestaurantPost = (restaurantName, city) =>
	axios.post(API_LINKS.PROPOSE_RESTAURANT, {
		restaurantName,
		city,
	});

export const sendContactEmail = (name, email, title, body) =>
	sendEmail(name, title, body, email)
		.then(() => navigate(LINKS.CONTACT_US_SEND))
		.catch(() => navigate(LINKS.CONTACT_US_FAILED));

export const proposeRestaurant = (name, city) =>
	proposeRestaurantPost(name, city)
		.then(() => navigate(LINKS.PROPOSE_RESTAURANT_SEND))
		.catch(() => navigate(LINKS.CONTACT_US_FAILED));

export const rateOrder = (orderId, foodRating, serviceRating, rating, comment) =>
	axios
		.post(`${API_LINKS.ORDER_RATINGS}${orderId}`, {
			foodRating,
			serviceRating,
			rating,
			comment,
			orderId,
		})
		.then(() => navigate(`${LINKS.COMMENT_AND_RATING_SEND}?orderId=${orderId}`))
		.catch(() => navigate(`${LINKS.COMMENT_AND_RATING_SEND}?orderId=${orderId}`));

export const getOrderRating = (orderId) => axios.get(`${API_LINKS.ORDER_RATINGS}${orderId}`);

export const rateApp = (rating) => axios.post(API_LINKS.RATE_APP, { rating });

export const getAppRating = () => axios.get(API_LINKS.RATE_APP);
