const USER_ID_TOKEN = 'userId';
const USER_EMAIL_TOKEN = 'userEmail';
const USER_FIRST_NAME_TOKEN = 'userFirstName';
const USER_LAST_NAME_TOKEN = 'userLastName';
const AUTH_TOKEN = 'token';
const USER_NIP = 'USER_NIP';
const POPUPS = 'popups';

export const isNotBuildPhase = typeof window !== 'undefined' && typeof localStorage !== 'undefined';

const getValueByToken = (token) => (isNotBuildPhase && localStorage.getItem(token) ? localStorage.getItem(token) : '');
const setValueByToken = (token, value) => {
	if (isNotBuildPhase) {
		localStorage.setItem(token, value || '');
	}
};

export const getPopup = () => getValueByToken(POPUPS);
export const addPopup = (popup) => {
	const currentPopups = getPopup();
	if (currentPopups) {
		setValueByToken(POPUPS, JSON.stringify([...JSON.parse(currentPopups), popup]));
	} else {
		setValueByToken(POPUPS, JSON.stringify([popup]));
	}
};
export const removePopup = (popup) => {
	const currentPopups = getPopup();
	if (currentPopups) {
		setValueByToken(POPUPS, JSON.stringify([...JSON.parse(currentPopups).filter((pop) => pop.split('-')[0] !== popup.split('-')[0])]));
	}
};

export const setUserId = (userId) => setValueByToken(USER_ID_TOKEN, userId);
export const getUserId = () => getValueByToken(USER_ID_TOKEN);

export const setUserEmailAddress = (emailAddress) => setValueByToken(USER_EMAIL_TOKEN, emailAddress);
export const getUserEmailAddress = () => getValueByToken(USER_EMAIL_TOKEN);

export const setUserFirstName = (name) => setValueByToken(USER_FIRST_NAME_TOKEN, name);
export const getUserFirstName = () => getValueByToken(USER_FIRST_NAME_TOKEN);

export const setUserLastName = (name) => setValueByToken(USER_LAST_NAME_TOKEN, name);
export const getUserLastName = () => getValueByToken(USER_LAST_NAME_TOKEN);

export const setAuthToken = (token) => setValueByToken(AUTH_TOKEN, token);
export const getAuthToken = () => getValueByToken(AUTH_TOKEN);

export const setNip = (nip) => setValueByToken(USER_NIP, nip);
export const getNip = () => getValueByToken(USER_NIP);

export const isUserLogged = () => !!(getValueByToken(USER_ID_TOKEN) && getValueByToken(USER_EMAIL_TOKEN));

export const saveAuthDataToLocalStorage = (id, email, firstName, lastName, token) => {
	setUserId(id);
	setUserEmailAddress(email);
	setUserFirstName(firstName);
	setUserLastName(lastName);
	setAuthToken(token);
};

export const removeAuthDataFromLocalStorage = () => {
	setUserId(null);
	setUserEmailAddress(null);
	setUserFirstName(null);
	setUserLastName(null);
	setAuthToken(null);
};
