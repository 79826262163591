import * as React from 'react';
import { SVGProps } from 'react';

const ContactIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		height={18}
		width={18}
		xmlns="http://www.w3.org/2000/svg"
		{...props}
		viewBox="0 0 18 18"
	>
		<path
			d="M0 0h18v18H0Z"
			data-name="Path 3816"
			fill="none"
		/>
		<path
			d="M16 5.25A1.335 1.335 0 0 0 14.6 4H3.4A1.335 1.335 0 0 0 2 5.25v7.5A1.335 1.335 0 0 0 3.4 14h11.2a1.335 1.335 0 0 0 1.4-1.25Zm-1.4 0L9 8.369 3.4 5.25Zm0 7.5H3.4V6.5L9 9.625 14.6 6.5Z"
			data-name="Path 3817"
			fill="#646464"
		/>
	</svg>
);

export default ContactIcon;
