import { RestaurantInterface } from 'restpay-api-lib/lib/@types/_interfaces/restaurants/restaurant.interface';
import { fetchRestaurant, fetchRestaurantComments } from '../services/restaurantService';
import { RestaurantStateTypesInterface } from './@types/restaurant/restaurantStateTypes.interface';

const initialState: RestaurantStateTypesInterface = {
	loading: false,
	error: false,
	fetched: false,
	data: null,
	comments: [],
};

const FETCH_RESTAURANT = 'FETCH_RESTAURANT';
const SET_RESTAURANT = 'SET_RESTAURANT';
const FETCH_RESTAURANT_COMMENTS = 'FETCH_RESTAURANT_COMMENTS';

export const fetchRestaurantAction = (restaurantId: string) => ({
	type: FETCH_RESTAURANT,
	payload: fetchRestaurant(restaurantId),
});

export const setRestaurantAction = (restaurant: RestaurantInterface) => ({
	type: SET_RESTAURANT,
	payload: restaurant,
});

export const fetchRestaurantCommentsAction = (restaurantId: string) => ({
	type: FETCH_RESTAURANT_COMMENTS,
	payload: fetchRestaurantComments(restaurantId),
});

export default (state = initialState, action) => {
	switch (action.type) {
		case `${FETCH_RESTAURANT}_PENDING`:
			return {
				...state,
				loading: true,
				error: true,
				fetched: false,
				data: null,
			};
		case `${FETCH_RESTAURANT}_REJECTED`:
			return {
				...initialState,
				loading: false,
				error: true,
				fetched: false,
				data: null,
			};
		case `${FETCH_RESTAURANT}_FULFILLED`:
			return {
				...state,
				loading: false,
				error: false,
				fetched: true,
				data: {
					...action.payload.data,
					businessHours: action?.payload?.data?.hours?.filter((hour) => hour.type === 'BUSINESS'),
				},
			};
		case `${FETCH_RESTAURANT_COMMENTS}_PENDING`:
			return {
				...state,
				comments: [],
				loading: true,
				error: false,
				fetched: false,
			};
		case `${FETCH_RESTAURANT_COMMENTS}_REJECTED`:
			return {
				...state,
				comments: [],
				loading: false,
				error: true,
				fetched: false,
			};
		case `${FETCH_RESTAURANT_COMMENTS}_FULFILLED`:
			return {
				...state,
				loading: false,
				error: false,
				fetched: true,
				comments: action.payload.data.filter((rating) => rating.commentApproved),
			};
		case SET_RESTAURANT:
			return {
				...state,
				loading: false,
				error: false,
				fetched: true,
				data: action.payload,
			};
		default:
			return state;
	}
};
