/* eslint-disable */
import * as React from "react";

const PaymentIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg height={18} viewBox="0 0 18 18" width={18} xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M0 0h18v18H0z" data-name="Path 3798" fill="none" />
        <path
            d="M15 3H3a1.488 1.488 0 00-1.493 1.5l-.007 9A1.5 1.5 0 003 15h12a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0015 3zm0 10.5H3V9h12zM15 6H3V4.5h12z"
            data-name="Path 3799"
            fill="#646464"
        />
    </svg>
);

export default PaymentIcon;
