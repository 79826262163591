import { proposeRestaurant } from "../services/contactService";

const initialState = {
	name: "",
	city: "",
	loading: false,
	error: false,
};

const CHANGE_PROPOSE_RESTAURANT_FORM_VALUE = "CHANGE_PROPOSE_RESTAURANT_FORM_VALUE";
const SEND_PROPOSE_RESTAURANT_EMAIL = "SEND_PROPOSE_RESTAURANT_EMAIL";
const CLEAR_PROPOSE_RESTAURANT_FORM = "CLEAR_PROPOSE_RESTAURANT_FORM";

export const sendProposeRestaurantEmailAction = (name, city) => ({
	type: SEND_PROPOSE_RESTAURANT_EMAIL,
	payload: proposeRestaurant(name, city),
});

export const changeProposeRestaurantValueAction = (name, value) => ({
	type: CHANGE_PROPOSE_RESTAURANT_FORM_VALUE,
	payload: {
		name,
		value,
	},
});

export const clearProposeRestaurantFormAction = () => ({
	type: CLEAR_PROPOSE_RESTAURANT_FORM,
});

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_PROPOSE_RESTAURANT_FORM:
			return {
				...initialState,
			};
		case `${SEND_PROPOSE_RESTAURANT_EMAIL}_PENDING`:
			return {
				...state,
				loading: true,
			};
		case `${SEND_PROPOSE_RESTAURANT_EMAIL}_REJECTED`:
			return {
				...state,
				loading: true,
				error: true,
			};
		case `${SEND_PROPOSE_RESTAURANT_EMAIL}_FULFILLED`:
			return {
				...state,
				loading: true,
				error: false,
			};
		case CHANGE_PROPOSE_RESTAURANT_FORM_VALUE:
			return {
				...state,
				[action.payload.name]: action.payload.value,
			};
		default:
			return state;
	}
};
