import * as React from "react";

const OpenDrawerIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg height={20.81} viewBox="0 0 20.81 22.66" width={22.66} xmlns="http://www.w3.org/2000/svg" {...props}>
        <g data-name="Kompozycja 1">
            <g data-name="Group 13" fill="#6b247a">
                <rect data-name="Rectangle 9" height={3.347} rx={1.663} transform="translate(0 8.731)" width={19.888} />
                <rect
                    data-name="Rectangle 10"
                    height={3.347}
                    rx={1.663}
                    transform="translate(0 17.463)"
                    width={15.73}
                />
                <rect data-name="Rectangle 11" height={3.347} rx={1.663} width={22.66} />
            </g>
        </g>
    </svg>
);

export default OpenDrawerIcon;
