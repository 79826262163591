import LogRocket from 'logrocket';
import { v4 as uuid } from 'uuid';
import React from 'react';
import {
	isUserLogged, getUserFirstName, getUserLastName, getUserEmailAddress,
} from './src/utils/localStorage';
import { setJWTToken } from './src/utils/login';
import { updatePopups } from './src/components/common/popup';
import Layout from './src/components/layout';

const onClientEntry = () => {
};

export { onClientEntry };

export const wrapPageElement = ({ element, props }) => <Layout {...props}>{element}</Layout>;

export { default as wrapRootElement } from './src/state/ReduxWrapper';

export const onServiceWorkerUpdateReady = () => {
	setTimeout(() => {
		updatePopups(true, 'update');
	}, 3000);
};

const isIos = () => {
	const userAgent = window.navigator.userAgent.toLowerCase();
	return /iphone|ipad|ipod/.test(userAgent);
};
// eslint-disable-next-line no-restricted-syntax
const isInStandaloneMode = () => 'standalone' in window.navigator && window.navigator.standalone;

export const onInitialClientRender = () => {
	setJWTToken();
	window.addEventListener('beforeinstallprompt', (e) => {
		e.preventDefault();
		setTimeout(() => {
			updatePopups(true, 'a2hs-android');
		}, 3000);
		window.androidA2HS = e;
	});
	window.addEventListener('appinstalled', () => {
		setTimeout(() => {
			updatePopups(false, 'a2hs-android');
		}, 3000);
	});
	if (isIos() && !isInStandaloneMode()) {
		setTimeout(() => {
			updatePopups(true, 'a2hs-ios');
		}, 3000);
	}
};
