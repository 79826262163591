import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import ListIcon from '../../../icons/listIcon';
import i18n from '../../../utils/i18n';

const DrawerLanguagePickWrapper = styled.div`
    display: flex;
    width: 100%;
`;

const DrawerLanguagePickTextWrapper = styled.div`
    display: flex;
    margin-left: 15px;
`;
const DrawerSingleLanguageWrapper = styled.div`
    display: flex;
`;
const DrawerSingleLanguageText = styled.div`
    &.active {
        font-weight: bold;
    }
`;

const DrawerLanguagePick = () => {
	const { pathname } = useLocation();
	const availableLanguages = ['pl-PL', 'en-EN', 'de-DE'];

	const changeLanguage = (lang) => {
		i18n.changeLanguage(lang);

		const splitedPathname = pathname.split('/');
		const splitedLanguages = availableLanguages.map((ln) => ln.slice(0, 2));

		splitedPathname.forEach((part) => {
			if (splitedLanguages.includes(part)) {
				navigate(pathname.replace(`/${part}/`, `/${lang.slice(0, 2)}/`));
			}
		});
	};
	return (
		<DrawerLanguagePickWrapper>
			<ListIcon />
			<DrawerLanguagePickTextWrapper>
				{availableLanguages.map((lang, index) => (
					<DrawerSingleLanguageWrapper>
						{index !== 0 && ' | '}
						<DrawerSingleLanguageText
							key={lang}
							className={i18n.language === lang && 'active'}
							onClick={() => changeLanguage(lang)}
						>
							{lang.slice(3)}
						</DrawerSingleLanguageText>
					</DrawerSingleLanguageWrapper>
				))}
			</DrawerLanguagePickTextWrapper>
		</DrawerLanguagePickWrapper>
	);
};
export default DrawerLanguagePick;
