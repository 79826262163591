exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-loading-js": () => import("./../../../src/pages/auth-loading.js" /* webpackChunkName: "component---src-pages-auth-loading-js" */),
  "component---src-pages-bill-error-js": () => import("./../../../src/pages/bill-error.js" /* webpackChunkName: "component---src-pages-bill-error-js" */),
  "component---src-pages-comment-and-rating-send-js": () => import("./../../../src/pages/comment-and-rating-send.js" /* webpackChunkName: "component---src-pages-comment-and-rating-send-js" */),
  "component---src-pages-contact-us-failed-js": () => import("./../../../src/pages/contact-us-failed.js" /* webpackChunkName: "component---src-pages-contact-us-failed-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-contact-us-send-js": () => import("./../../../src/pages/contact-us-send.js" /* webpackChunkName: "component---src-pages-contact-us-send-js" */),
  "component---src-pages-enter-code-tsx": () => import("./../../../src/pages/enter-code.tsx" /* webpackChunkName: "component---src-pages-enter-code-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-fb-js": () => import("./../../../src/pages/login/fb.js" /* webpackChunkName: "component---src-pages-login-fb-js" */),
  "component---src-pages-login-google-js": () => import("./../../../src/pages/login/google.js" /* webpackChunkName: "component---src-pages-login-google-js" */),
  "component---src-pages-login-index-js": () => import("./../../../src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-pages-order-history-js": () => import("./../../../src/pages/order-history.js" /* webpackChunkName: "component---src-pages-order-history-js" */),
  "component---src-pages-order-js": () => import("./../../../src/pages/order.js" /* webpackChunkName: "component---src-pages-order-js" */),
  "component---src-pages-payment-failed-js": () => import("./../../../src/pages/payment-failed.js" /* webpackChunkName: "component---src-pages-payment-failed-js" */),
  "component---src-pages-payment-status-js": () => import("./../../../src/pages/payment-status.js" /* webpackChunkName: "component---src-pages-payment-status-js" */),
  "component---src-pages-payment-success-js": () => import("./../../../src/pages/payment-success.js" /* webpackChunkName: "component---src-pages-payment-success-js" */),
  "component---src-pages-propose-restaurant-js": () => import("./../../../src/pages/propose-restaurant.js" /* webpackChunkName: "component---src-pages-propose-restaurant-js" */),
  "component---src-pages-propose-restaurant-send-js": () => import("./../../../src/pages/propose-restaurant-send.js" /* webpackChunkName: "component---src-pages-propose-restaurant-send-js" */),
  "component---src-pages-rate-us-js": () => import("./../../../src/pages/rate-us.js" /* webpackChunkName: "component---src-pages-rate-us-js" */),
  "component---src-pages-rate-us-send-js": () => import("./../../../src/pages/rate-us-send.js" /* webpackChunkName: "component---src-pages-rate-us-send-js" */),
  "component---src-pages-rating-js": () => import("./../../../src/pages/rating.js" /* webpackChunkName: "component---src-pages-rating-js" */),
  "component---src-pages-restaurants-js": () => import("./../../../src/pages/restaurants.js" /* webpackChunkName: "component---src-pages-restaurants-js" */),
  "component---src-pages-scan-qr-js": () => import("./../../../src/pages/scan-qr.js" /* webpackChunkName: "component---src-pages-scan-qr-js" */),
  "component---src-pages-table-leave-tip-tsx": () => import("./../../../src/pages/table/leave-tip.tsx" /* webpackChunkName: "component---src-pages-table-leave-tip-tsx" */),
  "component---src-pages-table-menu-details-cart-tsx": () => import("./../../../src/pages/table/menu/details/cart.tsx" /* webpackChunkName: "component---src-pages-table-menu-details-cart-tsx" */),
  "component---src-pages-table-menu-details-tsx": () => import("./../../../src/pages/table/menu/details.tsx" /* webpackChunkName: "component---src-pages-table-menu-details-tsx" */),
  "component---src-pages-table-menu-tsx": () => import("./../../../src/pages/table/menu.tsx" /* webpackChunkName: "component---src-pages-table-menu-tsx" */),
  "component---src-pages-table-orders-tsx": () => import("./../../../src/pages/table/orders.tsx" /* webpackChunkName: "component---src-pages-table-orders-tsx" */),
  "component---src-pages-table-tsx": () => import("./../../../src/pages/table.tsx" /* webpackChunkName: "component---src-pages-table-tsx" */),
  "component---src-templates-general-policy-js": () => import("./../../../src/templates/generalPolicy.js" /* webpackChunkName: "component---src-templates-general-policy-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacyPolicy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-restaurant-comments-js": () => import("./../../../src/templates/restaurantComments.js" /* webpackChunkName: "component---src-templates-restaurant-comments-js" */),
  "component---src-templates-restaurant-js": () => import("./../../../src/templates/restaurant.js" /* webpackChunkName: "component---src-templates-restaurant-js" */),
  "component---src-templates-restaurant-menu-js": () => import("./../../../src/templates/restaurantMenu.js" /* webpackChunkName: "component---src-templates-restaurant-menu-js" */)
}

