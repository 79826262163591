import { combineReducers } from "redux";
import payment from "./payment.reducer";
import order from "./order.reducer";
import proposeRestaurant from "./proposeRestaurant.reducer";
import contactUs from "./contactUs.reducer";
import rating from "./rating.reducer";
import user from "./user.reducer";
import orderHistory from "./orderHistory.reducer";
import paymentStatus from "./paymentStatus.reducer";
import restaurant from "./restaurant.reducer";
import rateUs from "./rateUs.reducer";
import layout from "./layout.reducer";
import orders from "./orders.reducer";
import newOrder from "./newOrder.reducer";
import table from "./table.reducer";
import menu from "./menu.reducer";
import cart from "./cart.reducer";

export default combineReducers({
	orders,
	order,
	newOrder,
	orderHistory,
	user,
	payment,
	restaurant,
	paymentStatus,
	layout,
	rating,
	rateUs,
	proposeRestaurant,
	contactUs,
	table,
	menu,
	cart,
});
