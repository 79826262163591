import React, {
	FC, useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import posed, { PoseGroup } from 'react-pose';
import { useDispatch, useSelector } from 'react-redux';
import { LINKS } from '../../constants/links';
import LogoIMG from '../../images/logo.png';
import BackSVG from '../../icons/back.svg';
import DotsSVG from '../../icons/dots.svg';
import { isNotBuildPhase } from '../../utils/localStorage';
import createRipple from '../common/ripple';
import OpenDrawerIcon from '../../icons/openDrawerIcon';
import { TopOptionsInterface } from '../../state/@types/layout/topOptions.interface';
import { GlobalState } from '../../state/@types/redux.interface';
import { setDrawerAction } from '../../state/layout.reducer';
import Drawer from './drawer/drawer';

const StyledHeaderFixed = styled.header`
  background-color: #fff;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.1), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  left: 0;
  padding: 0 30px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1299;
`;
const StyledHeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  height: ${({ theme }) => theme.constants.headerHeight};
  justify-content: space-between;
`;
const StyledHamburgerWrapper = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  position: relative;
  text-align: left;
  width: 40px;
  img {
    height: 20px;
  }
`;
const StyledCloseWrapper = styled(Link)`
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  width: 40px;
`;
const StyledDrawerWrapper = styled.div`
    width: 100vw;
`;
const StyledLogoLink = styled(Link)`
  align-items: center;
  display: flex;
  position: relative;
  svg {
    height: 55px;
  }
`;
const StyledOptionsWrapper = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  width: 40px;
  &.hidden {
    visibility: hidden;
  }
`;
const StyledOpenOptions = styled.button`
  background-color: transparent;
  border: none;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  outline: none;
  padding: 15px;
  position: relative;
  span {
    background-color: #000;
    border-radius: 100%;
    display: block;
    height: 4px;
    margin-top: 3px;
    width: 4px;
  }
`;
const StyledOptionsList = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  min-width: 150px;
  position: relative;
  button {
    background-color: #fff;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
    box-shadow: none;
    color: ${({ theme }) => theme.colors.brandPurple};
    font-size: 16px;
    font-weight: 300;
    line-height: 60px;
    outline: none;
    width: 100%;
    &:last-child {
      border-bottom: none;
    }
  }
`;

const Modal = posed.div({
	enter: {
		y: 0,
		opacity: 1,
		delay: 300,
		transition: {
			y: {
				type: 'spring',
				stiffness: 1000,
				damping: 15,
			},
			default: {
				duration: 300,
			},
		},
	},
	exit: {
		y: 50,
		opacity: 0,
	},
});
const StyledModal = styled(Modal)`
  background: #fff;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed !important;
  width: 100vw;
  z-index: 9999;
`;
const Shade = posed.div({
	enter: {
		opacity: 1,
	},
	exit: {
		opacity: 0,
	},
});
const StyledShade = styled(Shade)`
  background: rgba(0, 0, 0, 0.8);
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed !important;
  right: 0;
  top: 0;
  z-index: 9998;
`;

interface HeaderInterface {
    customLogoSrc?: string;
    customLogoUrl?: string;
    backTo?: string;
    topOptions?: TopOptionsInterface[];
}

const Header: FC<HeaderInterface> = ({
	customLogoSrc, customLogoUrl, backTo, topOptions,
}) => {
	const dispatch = useDispatch();
	const { isDrawerOpened } = useSelector((state: GlobalState) => state.layout);
	const [optionsOpen, setOptionsOpen] = useState(false);
	const optionsWrapper = useRef(null);

	const toggleDrawer = (open) => (event) => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		dispatch(setDrawerAction(open));
	};

	const onShowOptions = () => {
		setOptionsOpen(true);
	};

	const onHideOptions = () => {
		setOptionsOpen(false);
	};

	const handleClickOutside = (event) => {
		if (optionsWrapper && !optionsWrapper.current.contains(event.target)) {
			setOptionsOpen(false);
		}
	};

	const onOptionClick = (option) => {
		option.onClick();
		setOptionsOpen(false);
	};

	useEffect(() => {
		if (!isNotBuildPhase) {
			return;
		}
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<>
			<StyledHeaderFixed>
				<StyledHeaderWrapper>
					{!backTo && (
						<StyledHamburgerWrapper
							aria-label="Open drawer"
							onClick={(e) => {
								createRipple(e);
								toggleDrawer(true)(e);
							}}
						>
							<OpenDrawerIcon />
						</StyledHamburgerWrapper>
					)}
					{backTo && (
						<StyledCloseWrapper
							onClick={createRipple}
							to={backTo}
						>
							<BackSVG />
						</StyledCloseWrapper>
					)}
					<StyledLogoLink
						onClick={createRipple}
						to={customLogoUrl && customLogoSrc ? customLogoUrl : LINKS.HOMEPAGE}
					>
						<img
							alt="RestPay"
							src={customLogoSrc || LogoIMG}
							style={{
								maxHeight: '40px',
								maxWidth: '130px',
							}}
						/>
					</StyledLogoLink>
					<StyledOptionsWrapper
						ref={optionsWrapper}
						className={!topOptions ? 'hidden' : ''}
					>
						<StyledOpenOptions
							onClick={(e) => {
								createRipple(e);
								onShowOptions();
							}}
						>
							<DotsSVG />
						</StyledOpenOptions>
						<PoseGroup>
							{optionsOpen && [
								<StyledShade
									key="shade"
									className="shade"
									onClick={onHideOptions}
								/>,
								<StyledModal
									key="modal"
									className="modal"
								>
									<StyledOptionsList>
										{topOptions.map((option) => (
											<button
												key={option.label}
												onClick={(e) => {
													onOptionClick(option);
													createRipple(e);
												}}
												type="button"
											>
												{option.label}
											</button>
										))}
									</StyledOptionsList>
								</StyledModal>,
							]}
						</PoseGroup>
					</StyledOptionsWrapper>
				</StyledHeaderWrapper>
			</StyledHeaderFixed>
			<SwipeableDrawer
				anchor="left"
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}
				open={isDrawerOpened}
			>
				<StyledDrawerWrapper
					role="presentation"
				>
					<Drawer />
				</StyledDrawerWrapper>
			</SwipeableDrawer>
		</>
	);
};

export default Header;
