import styled from 'styled-components';

const AppButton = styled.button`
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.brandPurple};
  border-radius: 30px;
  color: ${({ theme }) => theme.colors.brandPurple};
  cursor: pointer;
  display: inline-block;
  font-size: 1.3rem;
  font-weight: 900;
  line-height: 30px;
  min-width: 215px;
  padding: 10px 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  z-index: 3;
  &:active,
  &:focus,
  &:visited {
    outline: none;
    text-decoration: none;
  }
  &.active,
  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.colors.brandPurple};
    color: #fff;
  }
  img,
  svg {
    display: inline-block;
    margin-right: 13.5px;
  }
  &.hover-blue {
    &.active,
    &:hover {
      color: ${({ theme }) => theme.colors.brandBlue};
    }
  }
  &[disabled] {
    background-color: ${({ theme }) => theme.colors.gray};
    border-color: ${({ theme }) => theme.colors.fontGray};
    color: ${({ theme }) => theme.colors.fontGray} !important;
  }
  @media (max-width: 340px) {
    min-width: 195px;
  }
`;

export default AppButton;
