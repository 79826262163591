import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import AppButton from "./common/button";
import AppText from "./common/texts/appText";
import { updatePopups } from "./common/popup";
import createRipple from "./common/ripple";

const ButtonWrapper = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.brandPurple};
	bottom: -50vh;
	color: #fff;
	display: flex;
	justify-content: center;
	left: 0;
	margin-top: -1px;
	padding: 24px 16px;
	position: fixed;
	transition: bottom 0.5s linear;
	width: 100%;
	z-index: 1000;
`;
const StyledTextWrapper = styled.div``;
const StyledText = styled.p`
	font-size: 1rem;
	font-weight: 600;
	margin: 0;
`;
const StyledButtonsWrapper = styled.div`
	display: flex;
	justify-content: center;
	padding-top: 15px;
`;
const StyledButton = styled(AppButton)`
	background-color: #fff;
	font-size: 0.9rem;
	line-height: 35px;
	min-width: auto;
	padding: 0 15px;
	&.gray {
		background-color: ${({ theme }) => theme.colors.brandPurple};
		color: #955ca2;
	}
	@media (min-width: 375px) {
		font-size: 1rem;
	}
`;
const StyledClose = styled(AppText)`
	color: #fff;
	cursor: pointer;
	font-size: 1.3rem;
	font-weight: 600;
	margin: 0;
	padding: 20px;
	position: absolute;
	right: -8px;
	text-transform: uppercase;
	top: -12px;
`;

const StyledRippleWrapper = styled.button`
	background-color: transparent;
	border: none;
	color: #fff;
	font-size: 1.3rem;
	font-weight: 600;
	outline: none;
	padding: 0;
	position: relative;
`;

const UpdateAvailable = () => {
	const { t } = useTranslation();
	const onClose = () => {
		updatePopups(false, "update");
	};
	const onClick = () => {
		window.location.reload();
		updatePopups(false, "update");
	};
	return (
		<ButtonWrapper id="update-available">
			<StyledClose
				onClick={(e) => {
					onClose();
					createRipple(e, "light");
				}}
			>
				<StyledRippleWrapper>X</StyledRippleWrapper>
			</StyledClose>
			<StyledTextWrapper>
				<StyledText>{t("updateAvailable.header")}</StyledText>
				<StyledText>{t("updateAvailable.text")}</StyledText>
				<StyledButtonsWrapper>
					<StyledButton onClick={onClick}>{t("updateAvailable.button")}</StyledButton>
				</StyledButtonsWrapper>
			</StyledTextWrapper>
		</ButtonWrapper>
	);
};

export default UpdateAvailable;
