import { LayoutStateTypesInterface } from "./@types/layout/layoutStateTypes.interface";

const initialState: LayoutStateTypesInterface = {
	customLogoSrc: null,
	customLogoUrl: null,
	backTo: null,
	topOptions: null,
	withoutHeader: false,
	isDrawerOpened: false,
};

const SET_LAYOUT = "SET_LAYOUT";
const CLEAR_LAYOUT = "CLEAR_LAYOUT";
const SET_DRAWER = "SET_DRAWER";

export const setLayoutAction = (settings: LayoutStateTypesInterface) => ({
	type: SET_LAYOUT,
	payload: settings,
});

export const setDrawerAction = (isOpen: boolean) => ({
	type: SET_DRAWER,
	payload: isOpen,
});

export const clearLayoutAction = (settings?: LayoutStateTypesInterface) => ({
	type: CLEAR_LAYOUT,
	payload: settings,
});

export default (state = initialState, action): LayoutStateTypesInterface => {
	switch (action.type) {
		case CLEAR_LAYOUT:
			return {
				...initialState,
				isDrawerOpened: state.isDrawerOpened,
			};
		case SET_LAYOUT:
			return {
				...state,
				...action.payload,
			};
		case SET_DRAWER:
			return {
				...state,
				isDrawerOpened: action.payload,
			};
		default:
			return state;
	}
};
