import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReceiptIcon from '../../../icons/receiptIcon';
import StoreIcon from '../../../icons/storeIcon';
import { GlobalState } from '../../../state/@types/redux.interface';
import PaymentIcon from '../../../icons/paymentIcon';
import FavouriteIcon from '../../../icons/favouriteIcon';
import ContactIcon from '../../../icons/contactIcon';
import i18n from '../../../utils/i18n';
import AddToHomeScreenAndroid from '../../A2HS/addToHomeScreenAndroid';
import DrawerMenuOption from './drawerMenuOption';
import DrawerLowerSection from './drawerLowerSection';
import DrawerUpperSection from './drawerUpperSection';
import DrawerLanguagePick from './drawerLanguagePick';

const DrawerWrapper = styled.div`
    height: 100%;
    left: 0;
    padding: 36px 26px;
    position: fixed;
    top: 0;
    width: 100%;
`;
const HorizontalLine = styled.div`
    background-color: #CFCFCF;
    height: 1px;
    margin-bottom: 20px;
    width: 100%;
`;

const Drawer: FC = () => {
	const { t } = useTranslation();
	const { table } = useSelector((state: GlobalState) => state.table);
	const [areSettingsOpened, setAreSettingsOpened] = useState(false);

	return (
		<DrawerWrapper>
			<DrawerUpperSection
				areSettingsOpened={areSettingsOpened}
				setAreSettingsOpened={setAreSettingsOpened}
			/>
			{!areSettingsOpened ? (
				<>
					<DrawerMenuOption
						closeDrawerOnClick
						icon={(
							<ReceiptIcon />
						)}
						label={t('common.payBill')}
						link={table?.id ? `/table?tableId=${table.id}` : '/scan-qr'}
					/>
					<DrawerMenuOption
						closeDrawerOnClick
						icon={(
							<StoreIcon />
						)}
						label={t('common.restaurantsList')}
						link="/restaurants"
					/>
					<HorizontalLine />
					<DrawerMenuOption
						boldLabel={false}
						closeDrawerOnClick
						icon={(
							<PaymentIcon />
						)}
						label={t('orderHistory.title')}
						link="/order-history"
					/>
					<AddToHomeScreenAndroid />
					{/* <DrawerMenuOption */}
					{/*	boldLabel={false} */}
					{/*	icon={( */}
					{/*		<FavouriteIcon /> */}
					{/*	)} */}
					{/*	label="Ulubione" */}
					{/*	link="/404" */}
					{/* /> */}
				</>
			) : (
				<>
					<DrawerMenuOption
						closeDrawerOnClick
						icon={(
							<ReceiptIcon />
						)}
						label={t('common.regulation')}
						link={`/${i18n.language.slice(0, 2).toLowerCase()}/general-policy`}
					/>
					<DrawerMenuOption
						closeDrawerOnClick
						icon={(
							<StoreIcon />
						)}
						label={t('common.privacyPolicy')}
						link={`/${i18n.language.slice(0, 2).toLowerCase()}/privacy-policy`}
					/>
					<HorizontalLine />
					<DrawerMenuOption
						boldLabel={false}
						closeDrawerOnClick
						icon={(
							<ContactIcon />
						)}
						label={t('common.contact')}
						link="/contact-us"
					/>
					<DrawerMenuOption
						boldLabel={false}
						closeDrawerOnClick
						icon={(
							<FavouriteIcon />
						)}
						label={t('common.proposeRestaurant')}
						link="/propose-restaurant"
					/>
					<DrawerMenuOption
						boldLabel={false}
						closeDrawerOnClick
						icon={(
							<FavouriteIcon />
						)}
						label={t('rateUs.title')}
						link="/rate-us"
					/>
					<DrawerLanguagePick />
				</>
			)}
			{!areSettingsOpened && (
				<DrawerLowerSection
					areSettingsOpened={areSettingsOpened}
					setAreSettingsOpened={setAreSettingsOpened}
				/>
			)}
		</DrawerWrapper>
	);
};

export default Drawer;
