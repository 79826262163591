import { getOrdersByTableCode, getOrdersByTableId } from '../services/orderService';
import { OrderStateTypesInterface } from './@types/order/orderStateTypes.interface';

const initialState: OrderStateTypesInterface = {
	fetched: false,
	loading: false,
	error: false,
	orders: [],
	currentOrder: null,
};

const FETCH_TABLE_ORDERS_BY_TABLE_CODE = 'FETCH_TABLE_ORDERS_BY_TABLE_CODE';
const CHANGE_CURRENT_ORDER = 'CHANGE_CURRENT_ORDER';
const FETCH_TABLE_ORDERS_BY_TABLE = 'FETCH_TABLE_ORDERS_BY_TABLE';
const CLEAR_TABLE_ORDERS = 'CLEAR_TABLE_ORDERS';

export const changeCurrentOrder = (currentOrder) => ({
	type: CHANGE_CURRENT_ORDER,
	payload: currentOrder,
});

export const fetchOrdersByTableCodeAction = (tableCode) => ({
	type: FETCH_TABLE_ORDERS_BY_TABLE_CODE,
	payload: getOrdersByTableCode(tableCode),
});

export const fetchOrdersByTableIdAction = (tableId) => ({
	type: FETCH_TABLE_ORDERS_BY_TABLE,
	payload: getOrdersByTableId(tableId),
});

export const clearTableOrdersAction = () => ({
	type: CLEAR_TABLE_ORDERS,
});

const handleOrdersFulfilled = (state, action) => {
	const { data } = action.payload;

	return {
		...state,
		fetched: true,
		loading: false,
		error: false,
		orders: data,
	};
};

export default (state = initialState, action): OrderStateTypesInterface => {
	switch (action.type) {
		case CLEAR_TABLE_ORDERS:
			return initialState;
		case `${CHANGE_CURRENT_ORDER}`:
			return {
				...state,
				currentOrder: action.payload,
			};
		case `${FETCH_TABLE_ORDERS_BY_TABLE}_PENDING`:
			return {
				...state,
				loading: true,
				fetched: false,
			};
		case `${FETCH_TABLE_ORDERS_BY_TABLE}_REJECTED`:
			return {
				...initialState,
				loading: false,
				error: true,
			};
		case `${FETCH_TABLE_ORDERS_BY_TABLE}_FULFILLED`:
			return handleOrdersFulfilled(state, action);
		default:
			return state;
	}
};
