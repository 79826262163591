/* eslint-disable */
import * as React from "react";

const SettingsIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg height={18} viewBox="0 0 18 18" width={18} xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M0 0h18v18H0z" data-name="Path 3823" fill="none" />
        <path
            d="M14.138 9.686a4.885 4.885 0 000-1.372L15.6 7.159a.356.356 0 00.083-.448L14.3 4.289a.345.345 0 00-.3-.175.32.32 0 00-.118.021l-1.722.7a5.038 5.038 0 00-1.169-.686l-.263-1.855A.339.339 0 0010.382 2H7.616a.339.339 0 00-.339.294l-.263 1.855a5.3 5.3 0 00-1.169.686l-1.722-.7A.387.387 0 004 4.114a.342.342 0 00-.3.175L2.319 6.711a.348.348 0 00.083.448l1.459 1.155A5.618 5.618 0 003.812 9a5.618 5.618 0 00.048.686L2.4 10.841a.356.356 0 00-.083.448L3.7 13.711a.345.345 0 00.3.175.32.32 0 00.118-.021l1.722-.7a5.039 5.039 0 001.169.686l.263 1.855a.339.339 0 00.344.294h2.766a.339.339 0 00.339-.294l.263-1.855a5.3 5.3 0 001.169-.686l1.722.7a.387.387 0 00.124.021.342.342 0 00.3-.175l1.383-2.422a.356.356 0 00-.083-.448zm-1.369-1.2A3.785 3.785 0 0112.8 9c0 .147-.014.3-.035.511l-.1.791.616.49.747.588-.484.847-.878-.357-.719-.294-.622.476a4.039 4.039 0 01-.864.511l-.733.3-.111.791-.138.945h-.964l-.131-.945-.111-.791-.733-.3a3.913 3.913 0 01-.851-.5l-.629-.49-.733.3-.878.357-.484-.847.747-.588.616-.49-.1-.791C5.209 9.3 5.2 9.14 5.2 9s.014-.3.035-.511l.1-.791-.616-.49-.754-.588.484-.847.878.357.719.294.622-.476a4.039 4.039 0 01.864-.511l.733-.3.111-.791.139-.946h.961l.131.945.111.791.733.3a3.912 3.912 0 01.851.5l.629.49.733-.3.878-.357.484.847-.74.6-.616.49.1.791zM9 6.2A2.8 2.8 0 1011.766 9 2.783 2.783 0 009 6.2zm0 4.2A1.4 1.4 0 1110.382 9 1.4 1.4 0 019 10.4z"
            data-name="Path 3824"
            fill="#646464"
        />
    </svg>
);

export default SettingsIcon;
