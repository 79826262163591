import axios from "axios";
import { getAuthToken } from "./localStorage";

export const setJWTToken = () => {
	const token = getAuthToken();
	if (!token) {
		return;
	}
	axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const unsetJWTToken = () => {
	axios.defaults.headers.common.Authorization = null;
};
