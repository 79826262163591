/* eslint-disable */
import * as React from "react";

const CloseButtonIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg height={16.936} width={16.936} viewBox="0 0 16.936 16.936" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M13.948 16.423l-5.48-5.48-5.48 5.479a1.75 1.75 0 01-2.475-2.475l5.48-5.479-5.48-5.48A1.75 1.75 0 012.988.513l5.48 5.48 5.48-5.48a1.75 1.75 0 012.475 2.475l-5.48 5.48 5.48 5.48a1.75 1.75 0 01-2.475 2.475z"
            fill="#6b257b"
        />
    </svg>
);

export default CloseButtonIcon;
