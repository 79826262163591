import React, { useEffect, useState } from 'react';
import {
	addPopup, getPopup, isNotBuildPhase, removePopup,
} from '../../utils/localStorage';

export const updatePopups = (action, popup) => {
	if (action) {
		addPopup(popup);
		document.dispatchEvent(new CustomEvent('lsAdded'));
	} else {
		removePopup(popup);
		document.dispatchEvent(new CustomEvent('lsRemoved'));
	}
};

const Popup = (props) => {
	const { cookiesAccepted } = props;
	const [popupQueue, setPopupQueue] = useState([]);

	const refreshQueue = () => {
		if (getPopup()) {
			setPopupQueue(JSON.parse(getPopup()));
		} else {
			setPopupQueue('');
		}
	};

	if (isNotBuildPhase) {
		document.addEventListener('lsAdded', () => {
			refreshQueue();
		});

		document.addEventListener('lsRemoved', () => {
			refreshQueue();
			document.body.className = '';
		});
	}

	useEffect(() => {
		refreshQueue();
	}, [cookiesAccepted]);

	useEffect(() => {
		if (cookiesAccepted && popupQueue[0]) {
			document.body.classList.add(popupQueue[0]);
		}
	}, [popupQueue]);

	return <div />;
};

export default Popup;
